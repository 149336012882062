import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { FaArrowLeft } from 'react-icons/fa'
// import { Link } from 'react-router-dom'

const RedirectUrl = (props) => {
    const [loadDone, setLoadDone] = useState(false)
    setTimeout(() => {
        setLoadDone(true)
    }, 1000);
    const onRedirect = () => {
        window.open('https://' + props.location.pathname.split('https://').pop() + props.location.search, '_blank')
        setTimeout(() => {
            props.history.goBack()
        }, 500)
    }
    return (
        <div style={{ display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: 30, margin: 30 }} >
            {/* <Link to ={'https://' + props.location.pathname.split('https://').pop() + props.location.search} target='_blank' /> */}
            {loadDone ? <Button onClick={() => onRedirect()} ><FaArrowLeft /> GO BACK</Button> : <h1 style={{ marginBottom: 40 }} >Loading ...</h1>}
        </div>
    )
}

export default RedirectUrl