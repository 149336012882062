import React, { useState } from 'react'
import { Form, FormGroup, Input, Label, Button, Col, Spinner } from 'reactstrap'
import { FaLine, FaFacebook } from 'react-icons/fa'
import { RiEarthLine } from 'react-icons/ri'
import Axios from '../../Services/Axios'

const ContactForm = () => {
    const [loading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [mobile, setMobile] = useState(null)
    const [email, setEmail] = useState(null)
    const [lineId, setLineId] = useState(null)
    const [detail, setDetail] = useState(null)
    const [message, setMessage] = useState(null)

    const addContactInfo = () => {
        setLoading(true)
        if (firstName === null || mobile === null) {
            alert('กรุณากรอกรายละเอียด')
            setLoading(false)
        } else {
            Axios({
                method: 'POST',
                url: '/addcontactinfo',
                data: {
                    firstName: firstName,
                    lastName: lastName,
                    mobile: mobile,
                    email: email,
                    lineId: lineId,
                    detail: detail
                }
            }).then((res) => {
                // console.log(res.data)
                setMessage('คุณได้ส่งข้อความถึงเจ้าหน้าที่แล้ว กรุณารอติดต่อกลับ...')
                setLoading(false)
            }).catch((err) => {
                console.log(err)
                setMessage('ส่งข้อความไม่สำเร็จ!!!')
                setLoading(false)
            })
        }
    }
    return (
        <Form style={{ justifyContent: 'center', backgroundColor: '#f2f2f2', padding: 15, marginTop: 20, marginBottom: 20 }}>
            <FormGroup>
                <h4>สอบถามรายละเอียด</h4>
            </FormGroup>
            <FormGroup row >
                <Col md={6}>
                    <Label for='firstname'>ชื่อ *</Label>
                    <Input type='text' placeholder='First Name' id='firstname' onChange={(e) => {
                        setFirstName(e.target.value)
                        setMessage('')
                    }} />
                </Col>
                <Col md={6} >
                    <FormGroup>
                        <Label>นามสกุล</Label>
                        <Input type='text' placeholder='Last Name' onChange={(e) => { setLastName(e.target.value) }} />
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col md={6}>
                    <FormGroup >
                        <Label>เบอร์โทร *</Label>
                        <Input type='text' placeholder='Mobile Number' onChange={(e) => { setMobile(e.target.value) }} />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup >
                        <Label>อีเมล</Label>
                        <Input type='text' placeholder='Email' onChange={(e) => { setEmail(e.target.value) }} />
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col>
                    <Label>ไลน์ ID</Label>
                    <Input type='text' placeholder='Line ID' onChange={(e) => { setLineId(e.target.value) }} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col>
                    <Label>คำถาม : คุณต้องการทำเสื้อผ้าแบบไหน? (ให้เราแนะนำนะนำนะคะ)</Label>
                    <Input type='textarea' placeholder='Question' onChange={(e) => { setDetail(e.target.value) }} />
                </Col>
            </FormGroup>
            <FormGroup>
                {loading ? (<Spinner />) : (<Button outline onClick={() => { addContactInfo() }}  >ส่งข้อความถึงเจ้าหน้าที่</Button>)}
                <a style={{ margin: 15 }} href='https://fb.com/kpmtextile' target="_blank" ><FaFacebook style={{ fontSize: 28 }} /></a>
                <a style={{ margin: 15 }} href='https://lin.ee/fnVRtge' target="_blank" ><FaLine style={{ fontSize: 28, color: 'green' }} /></a>
                <a style={{ margin: 15 }} href='https://kpmtextile.com' target="_blank" ><RiEarthLine style={{ fontSize: 28, color: '#000' }} /></a>
            </FormGroup>
            <FormGroup>
                <p style={{ color: 'red' }}>{message}</p>
            </FormGroup>
        </Form>
    )
}

export default ContactForm
