import { combineReducers } from 'redux'
import productGroupReducers from './ProductReducers/ProductGroupReducers'
import productDetailReducers from './ProductReducers/ProductDetailReducers'
import productItemReducers from './ProductReducers/ProductItemReducers'
import productDetailAllReducers from './ProductReducers/ProductDetailAllReducers'
import proudctSearchReducers from './ProductReducers/ProductSearchReducers'
import productItemDetailReducers from './ProductReducers/PrdouctItemDetailReducers' 
import userReducers from './UserReducers/UserReducers'
import shoppingCartsReducers from './ShoppingCartsReducers/ShoppingCartsReducers'

const rootReducers = combineReducers({
    productGroup: productGroupReducers,
    productDetail:productDetailReducers,
    productItem:productItemReducers,
    productItemDetail:productItemDetailReducers,
    productDetailAll:productDetailAllReducers,
    productSearch:proudctSearchReducers,
    users:userReducers,
    shoppingCarts:shoppingCartsReducers
})

export default rootReducers