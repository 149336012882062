import { 
    SET_SHOPPING_CART_UNCONFIRM_DATA,
    SET_SHOPPING_CART_UNCONFIRM_COUNT,
    SET_SHOPPING_CART_UNCONFIRM_TOTAL
 } from '../../Type'

const shoppingCartReducers = (state = {
    shoppingCartUnConfirmData: [],
    shoppingCartUnconfirmCount:0,
    shoppingCartUnconfirmTotal : 0
}, action) => {
    switch (action.type) {
        case SET_SHOPPING_CART_UNCONFIRM_DATA:
            state = {
                ...state,
                shoppingCartUnConfirmData: action.playload
            }
            break
        case SET_SHOPPING_CART_UNCONFIRM_COUNT:
            state={
                ...state,
                shoppingCartUnconfirmCount:action.playload
            }
            break
        case SET_SHOPPING_CART_UNCONFIRM_TOTAL:
            state={
                ...state,
                shoppingCartUnconfirmTotal:action.playload
            }
            break
        default:
    }
    return state
}

export default shoppingCartReducers