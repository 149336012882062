import { 
    SET_USER_EMAIL, 
    SET_USER_ID, 
    SET_USER_IS_LOGIN, 
    SET_USER_MOBILE, 
    SET_USER_TOKEN, 
    SET_USER_LAST_NAME,
    SET_USER_FIRST_NAME,
    SET_RESET_PASSWORD_EMAIL,
    SET_VERIFY_EMAIL_ADDRESS,
    SET_EMAIL_IS_NULL
} from '../../Type'

export const setUserIsLogin = (data)=>({
    type:SET_USER_IS_LOGIN,
    playload:data
})
export const setUserId = (data)=>({
    type:SET_USER_ID,
    playload:data
})
export const setUserEmail = (data)=>({
    type:SET_USER_EMAIL,
    playload:data
})
export const setUserMobile = (data)=>({
    type:SET_USER_MOBILE,
    playload:data
})
export const setUserToken = (data)=>({
    type:SET_USER_TOKEN,
    playload:data
})
export const setUserLastName = (data)=>({
    type:SET_USER_LAST_NAME,
    playload:data
})
export const setUserFirstName= (data)=>({
    type:SET_USER_FIRST_NAME,
    playload:data
})

export const setResetPasswordEmail = (data)=>({
    type:SET_RESET_PASSWORD_EMAIL,
    playload:data
})

export const setVerifyEmailAddress = (data)=>({
    type:SET_VERIFY_EMAIL_ADDRESS,
    playload:data
})

export const setEmailIsNull = (data)=>({
    type: SET_EMAIL_IS_NULL,
    playload:data
})