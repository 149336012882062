import React, { useState } from 'react'
import { Container, Card, CardBody, CardHeader, CardFooter, Button, Input, Spinner } from 'reactstrap'
import Axios from '../../Services/Axios'
import { connect } from 'react-redux'
import { setVerifyEmailAddress, setEmailIsNull } from '../../Redux/Actions/UserActions/UserActions'

const VerifyUser = (props) => {
    const [verifyCode, setVerifyCode] = useState(null)
    const [message, setMessage] = useState(null)
    const [verifying, setVerifying] = useState(false)

    const onVerifyEmail = async () => {
        if (verifyCode === null) {
            setMessage('กรุณากรอกรหัส Verify Code')
        } else {
            setVerifying(true)
            await Axios({
                method: 'POST',
                url: '/verifyusers',
                data: {
                    verifyCode: verifyCode,
                    email: props.users.verifyEmailAddress
                }
            }).then((res) => {
                // console.log(res)
                setVerifying(false)
                setMessage('Verify Successful ...')
                setTimeout(() => {
                    props.history.push('/signin')
                }, 500)
            }).catch((err) => {
                // console.log(err)
                setVerifying(false)
                setMessage('Invalit verify code !!!')
            })
        }
    }

    return (
        <Container>
            <Card>
                <CardHeader>Verify Your Email ...</CardHeader>
                <CardBody>
                    {!props.users.emailIsNull ? (null) : (
                        <Input type='text' placeholder='Your Email' style={{ margin: 5 }}
                            onChange={(e) => {
                                props.setVerifyEmailAddress(e.target.value)
                            }} />
                    )}
                    <Input type='number' style={{ margin: 5 }} onChange={(e) => {
                        setVerifyCode(e.target.value)
                        setMessage(null)
                    }} placeholder='Verify code , From your email ...' />
                </CardBody>
                <CardFooter>
                    {verifying ? (<Spinner />) : (
                        <Button outline onClick={() => { onVerifyEmail() }} >Verify Now ...</Button>
                    )}
                    <p style={{ margin: 5, color: 'red' }}> {message}</p>
                </CardFooter>
            </Card>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    users: state.users
})

const mapDispatchToProps = (dispatch) => ({
    setVerifyEmailAddress: ((data) => {
        dispatch(setVerifyEmailAddress(data))
    }),
    setEmailIsNull: ((data) => {
        dispatch(setEmailIsNull(data))
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser)