import {
    SET_USER_EMAIL,
    SET_USER_ID,
    SET_USER_IS_LOGIN,
    SET_USER_MOBILE,
    SET_USER_FIRST_NAME,
    SET_USER_LAST_NAME,
    SET_USER_TOKEN,
    SET_RESET_PASSWORD_EMAIL,
    SET_VERIFY_EMAIL_ADDRESS,
    SET_EMAIL_IS_NULL
} from '../../Type'

const userReducers = (state = {
    userId: null,
    userEmail: null,
    userMobile: null,
    userIsLogin: false,
    resetPasswordEmail: null,
    verifyEmailAddress: null,
    emailIsNull: true
}, action) => {
    switch (action.type) {
        case SET_USER_ID:
            state = {
                ...state,
                userId: action.playload
            }
            break
        case SET_USER_IS_LOGIN:
            state = {
                ...state,
                userIsLogin: action.playload
            }
            break
        case SET_USER_EMAIL:
            state = {
                ...state,
                userEmail: action.playload
            }
            break
        case SET_USER_MOBILE:
            state = {
                ...state,
                userMobile: action.playload
            }
            break

        case SET_USER_TOKEN:
            state = {
                ...state,
                userToken: action.playload
            }
            break

        case SET_USER_LAST_NAME:
            state = {
                ...state,
                userLastName: action.playload
            }
            break

        case SET_USER_FIRST_NAME:
            state = {
                ...state,
                userFirstName: action.playload
            }
            break
        case SET_RESET_PASSWORD_EMAIL:
            state = {
                ...state,
                resetPasswordEmail: action.playload
            }
            break
        case SET_VERIFY_EMAIL_ADDRESS:
            state = {
                ...state,
                verifyEmailAddress: action.playload
            }
            break
        case SET_EMAIL_IS_NULL:
            state = {
                ...state,
                emailIsNull: action.playload
            }
            break
        default:
    }
    return state
}

export default userReducers