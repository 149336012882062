import React, { useState, useEffect } from 'react'
import Axios from '../../Services/Axios'
import { Row } from 'reactstrap'
import DataTable from 'react-data-table-component'
import dayjs from 'dayjs'

const SecondTab = () => {
  const [shoppingCartConfirmedData, setShoppingCartConfirmedData] = useState([])
  useEffect(() => {
    getShoppingCartConfirmedData()
  },[])

  const getShoppingCartConfirmedData = async () => {
    Axios({
      method: 'POST',
      url: '/getshoppingcartconfirmed',
      data: {
        status: 'CONFIRMED',
        userId: 2
      }
    }).then((res) => {
      // console.log(res)
      setShoppingCartConfirmedData(res.data.data)
    }).catch((err) => { console.log(err) })
  }
  // console.log(shoppingCartConfirmedData)

  const tableColumn = [
    {
      name: 'Date',
      selector: 'time_stamp',
      format: row => dayjs(row.time_stamp).format('DD/MM/YYYY')
    },
    {
      name: 'Code',
      selector: 'pdi_code'
    },
    {
      name: 'Article',
      selector: 'pdi_article'
    },
    {
      name: 'Quantity',
      selector: 'qty'
    },
    {
      name: 'Name',
      selector: 'pdi_name'
    },
    {
      name: 'Status',
      selector: 'status'
    }
  ]

  return (
    <Row>
      <DataTable
        data={shoppingCartConfirmedData}
        columns={tableColumn}
        striped={true}
        paginationServer={true}
        paginationPerPage={5}
      />
    </Row>
  )
}
export default SecondTab