import { 
    SET_PRODUCT_ITEM_DETAIL_DATA, 
    SET_PRODUCT_ITEM_DETAIL_LOADING, 
    SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY_PLUS, 
    SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY_MINER,
    SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY
} from '../../Type'

export const setProductItemDetailData = (data) => ({
    type: SET_PRODUCT_ITEM_DETAIL_DATA,
    playload: data
})

export const setProductItemDetailLoading = (data) => ({
    type: SET_PRODUCT_ITEM_DETAIL_LOADING,
    playload: data
})

export const setProductItemDetailBookingQtyPlus = (data) => ({
    type: SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY_PLUS,
    playload: data
})

export const setProductItemDetailBookingQtyMiner = (data) => ({
    type: SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY_MINER,
    playload: data
})

export const setProductItemDetailBookingQty = (data) => ({
    type: SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY,
    playload: data
})