import React, { useEffect } from 'react'
import { Container } from 'reactstrap'
import UnconfirmTab from './UnconfirmTab'
import { connect } from 'react-redux'
import Axios from '../../Services/Axios'
import { setShoppingCartUnConfirmData, setShoppingCartUnconfirmCount, setShoppingCartUnconfirmTotal } from '../../Redux/Actions/ShoppingCartsActions/ShoppingCartsActions'


const ShoppingCarts = ({setShoppingCartUnConfirmData,users, setShoppingCartUnconfirmCount, setShoppingCartUnconfirmTotal}) => {

    useEffect(() => {
        getShoppingCartsUnConfirmData()
    }, [])

    const getShoppingCartsUnConfirmData = async () => {
        setTimeout(() => {
            if (users.userToken === null) { console.log('Did not login') } else {
                Axios({
                    method: 'POST',
                    url: '/getcart',
                    data: {
                        status: 'UNCONFIRM',
                        user_id: users.userId
                    },
                    headers: {
                        Authorization: 'Bearer ' + users.userToken
                    }
                }).then((res) => {
                    setShoppingCartUnConfirmData(res.data.data)
                    // console.log(res)
                    const cartItem = res.data.data
                    var totalCarts = 0
                    for (var i = 0; i < cartItem.length; i++) {
                        totalCarts += Number(cartItem[i].qty)
                    }
                    setShoppingCartUnconfirmTotal(totalCarts)
                    setShoppingCartUnconfirmCount(cartItem.length)
                }).catch((err) => {
                    console.log(err)
                })
            }
        }, 200)
    }

    return (
        <Container>
            <UnconfirmTab getShoppingCartsUnConfirmData={getShoppingCartsUnConfirmData} />
        </Container>
    )
}

const mapStateToProps = (state) => ({
    users: state.users,
    shoppingCarts: state.shoppingCarts
})

const mapDispatchToProps = (dispatch) => ({
    setShoppingCartUnConfirmData: (data) => {
        dispatch(setShoppingCartUnConfirmData(data))
    },
    setShoppingCartUnconfirmCount: (data) => {
        dispatch(setShoppingCartUnconfirmCount(data))
    },
    setShoppingCartUnconfirmTotal: (data) => {
        dispatch(setShoppingCartUnconfirmTotal(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCarts)