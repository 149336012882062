import { SET_PRODUCT_DETAIL_ALL_DATA, SET_PRODUCT_DETAIL_ALL_LOADING } from '../../Type'

const productDetailAllReducers = (state = {
    productDetailAllData: [],
    loading: false
}, action) => {
    switch (action.type) {
        case SET_PRODUCT_DETAIL_ALL_DATA:
            state = {
                ...state,
                productDetailAllData: action.setData
            }
            break
        case SET_PRODUCT_DETAIL_ALL_LOADING:
            state = {
                ...state,
                loading: action.setLoading
            }
            break
        default:
    }
    return state
}

export default productDetailAllReducers