import React, { useState } from 'react'
import Axios from '../../Services/Axios'
import { Container, Row, Button, Table, Modal, CardImg, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap'
// import { setShoppingCartUnconfirmCount } from '../../Redux/Actions/ShoppingCartsActions/ShoppingCartsActions'
import { connect } from 'react-redux'
import { FaTrash } from 'react-icons/fa'
import dayjs from 'dayjs'
import imageUrl from '../../Services/ImageUrl'

const ShoppingCarts = (props) => {

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const setToggleModal = () => { setModalIsOpen(!modalIsOpen) }
    const [selectId, setSelectId] = useState(null)
    const [selectItem, setSelectItem] = useState([])
    const [confirmItem, setConfirmItem] = useState(false)
    const setConfirmToogle = ()=>{setConfirmItem(!confirmItem)}
    // const [isChecked, setIsChecked] = useState(false)
    const numberFormat = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }


    const selectCartItem = (e) => {

        let index = selectItem.indexOf(e)
        if (index > -1) {
            selectItem.splice(index, 1)
        } else {
            let pushSelectItem = selectItem.concat(e)
            setSelectItem(pushSelectItem)
        }
    }

    const onConfirmShoppingCartItem = () => {
        Axios({
            method: 'POST',
            url: '/confirmshoppingcart',
            data: {
                cartId: selectItem,
                userId:props.users.userId
            },
            headers: {
                Authorization: 'Bearer ' + props.users.userToken
            }
        }).then((res) => {
            // console.log(res)
            props.getShoppingCartsUnConfirmData()
            setConfirmToogle()
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <Container>
            <Row className='justify-content-center' style={{ margin: 10 }} >
                <h2>Wellcome {props.users.userFirstName + ' ' + props.users.userLastName}'s Booking Orders</h2>
            </Row>
            <Row className='justify-content-center' >
                {props.shoppingCarts.shoppingCartUnconfirmCount === 0?(
                    <h5>Nothing in the shopping carts</h5>
                ):(
                <Button outline onClick={()=>{setConfirmToogle()}} >Confim Select Items</Button>
                )}
                </Row>
            <Row>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Select</th>
                            <th>Image</th>
                            <th>Date/Time</th>
                            <th>Product Code/Name</th>
                            <th>Quantity</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.shoppingCarts.shoppingCartUnConfirmData.map((rows, index) => (
                            <tr key={index} >
                                {/* <td>{rows.id}</td> */}
                                <td className='justify-content-end' >
                                    <Input type="checkbox" onChange={(e) => {
                                        selectCartItem(rows.id)
                                    }} />
                                </td>
                                <td>
                                    <CardImg style={{ width: 140, height: undefined }} src={imageUrl.baseUrl + '/pdi/' + rows.pdi_code + '/' + rows.pdi_image} />
                                </td>
                                <td>
                                    <div>
                                        {dayjs(rows.time_stamp).format('DD/MM/YYYY')}
                                    </div>
                                    <div style={{ fontSize: 14, color: 'grey' }} >
                                        {dayjs(rows.time_stamp).format('HH:mm:ss')}
                                    </div>
                                </td>
                                <td>
                                    <div>{rows.pdi_article}</div>
                                    <div style={{ fontSize: 12, color: 'grey' }} >{rows.pdi_name}</div>
                                </td>
                                <td>{numberFormat(rows.qty.toFixed(2))} Y</td>
                                <td>
                                    <Button outline onClick={() => {
                                        setSelectId(rows.id)
                                        setModalIsOpen(true)
                                    }} >
                                        <FaTrash e={{ fontSize: 30, margin: 5 }} />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th>Count :</th>
                            <th>{props.shoppingCarts.shoppingCartUnconfirmCount}</th>
                            <th>TOTAL : </th>
                            <th>{numberFormat(Number(props.shoppingCarts.shoppingCartUnconfirmTotal).toFixed(2))}</th>
                            <th>Yards</th>
                        </tr>
                    </tfoot>
                </Table>
                <Modal isOpen={modalIsOpen} toggle={setToggleModal} >
                    <ModalHeader>
                        ต้องการลบรายการนี้ทิ้งหรือไหม่ !!!
                    </ModalHeader>
                    <ModalBody>
                        ไม่สามารนำรายการนี้กลับมาอีกได้
                    </ModalBody>
                    <ModalFooter>
                        <Button outline onClick={async () => {
                            const token = localStorage.getItem('KPM_WEB_TOKEN')
                            await Axios({
                                method: 'DELETE',
                                url: '/deletecartitem',
                                data: {
                                    id: selectId
                                },
                                headers: {
                                    Authorization: 'Bearer ' + token
                                }
                            }).then((res) => {
                                // console.log(res)
                                props.getShoppingCartsUnConfirmData()
                                setModalIsOpen(false)
                            }).catch((err) => {
                                console.log(err)
                            })
                        }}>Yes</Button>
                        <Button outline onClick={() => {
                            setModalIsOpen(false)
                        }}>No</Button>
                    </ModalFooter>
                </Modal>
                <Modal toggle={setConfirmToogle} isOpen={confirmItem} >
                    <ModalHeader>
                        Confirm your selected products
                    </ModalHeader>
                    <ModalBody>

                    </ModalBody>
                    <ModalFooter>
                        <Button outline onClick={()=>{onConfirmShoppingCartItem()}} >Confim Now ...</Button>
                        <Button outline onClick={()=>{setConfirmToogle()}}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    users: state.users,
    shoppingCarts: state.shoppingCarts
})


// const mapDispatchToProps = (dispatch) => ({
//     setShoppingCartUnconfirmCount: (data) => {
//         dispatch(setShoppingCartUnconfirmCount(data))
//     }
// })

export default connect(mapStateToProps, null)(ShoppingCarts)