import React, { useEffect } from 'react'
import { Container, Col, Row, Card, CardFooter, CardImg, Spinner, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import axios from '../../Services/Axios'
import ImageUrl from '../../Services/ImageUrl'
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { setProductDetailData, setProductDetailLoading } from '../../Redux/Actions/ProductActions/ProductDetailActions'

const ProductDetail = ({ productDetailData, loading, setProductDetailData, setProductDetailLoading }) => {

    useEffect(() => {
        getProductDetailData()
    }, [])

    const { id } = useParams()

    const getProductDetailData = async () => {
        setProductDetailLoading(true)
        axios({
            mehtod: 'GET',
            url: `/pdd/${id}`
        }).then((res) => {
            setProductDetailData(res.data.data)
            setProductDetailLoading(false)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <Container>
            <Row>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to='/'>Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to='/product'>Categories</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to='/products'>All Products</Link>
                    </BreadcrumbItem>
                </Breadcrumb>
            </Row>
            <Row style={{ justifyContent: 'center' }} >
                {!loading ? productDetailData.map((items, index) => (
                    <Col key={index} sm={5} md={4} >
                        <Link to={`/productitem/` + items.pdd_code}>
                            <Card style={{ marginBottom: 20, marginTop: 20 }} >
                                <CardImg src={ImageUrl.baseUrl + '/pdd/' + items.pdd_img} />
                                <CardFooter>{items.pdd_name}</CardFooter>
                            </Card>
                        </Link>
                    </Col>
                )) : (
                    <Spinner type="grow" color="primary" style={{ marginTop: 100, marginBottom: 100 }} />
                )}
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    productDetailData: state.productDetail.productDetailData,
    loading: state.productDetail.loading
})

const mapDispatchToProps = (dispatch) => ({
    setProductDetailData: (data) => {
        dispatch(setProductDetailData(data))
    },
    setProductDetailLoading: (loading) => {
        dispatch(setProductDetailLoading(loading))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail)