import { SET_PRODUCT_ITEM_DATA, SET_PRODUCT_ITEM_LOADING, SET_PRODUCT_ITEM_STOCK, SET_PRODUCT_ITEM_ARTICLE, SET_PRODUCT_ITEM_IMAGE, SET_PRODUCT_ITEM_NAME } from '../../Type'

const productItemReducers = (state = {
    productItemData: [],
    loading: false,
    stock: '',
    article: '',
    image: '',
    name:''
}, action) => {
    switch (action.type) {
        case SET_PRODUCT_ITEM_DATA:
            state = {
                ...state,
                productItemData: action.setData
            }
            break
        default:
        case SET_PRODUCT_ITEM_LOADING:
            state = {
                ...state,
                loading: action.setLoading
            }
            break
        case SET_PRODUCT_ITEM_STOCK:
            state = {
                ...state,
                stock: action.setStock
            }
            break
        case SET_PRODUCT_ITEM_ARTICLE:
            state = {
                ...state,
                article: action.setArticle
            }
            break
        case SET_PRODUCT_ITEM_IMAGE:
            state = {
                ...state,
                image: action.setImage
            }
            break
        case SET_PRODUCT_ITEM_NAME:
            state={
                ...state,
                name:action.setName
            }
            break
    }
    return state
}

export default productItemReducers