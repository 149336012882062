// Product Group
export const SET_PRODUCT_GROUP_DATA = 'SET_PRODUCT_GROUP_DATA'
export const SET_PRODUCT_GROUP_LOADING = 'SET_PRODUCT_GROUP_LOADING'

//Product Detail
export const SET_PRODUCT_DETAIL_DATA = 'SET_PRODUCT_DETAIL_DATA'
export const SET_PRODUCT_DETAIL_LOADING = 'SET_PRODUCT_DETAIL_LOADING'

//Product Items
export const SET_PRODUCT_ITEM_DATA = 'SET_PRODUCT_ITEM_DATA'
export const SET_PRODUCT_ITEM_LOADING = 'SET_PRODUCT_ITEM_LOADING'
export const SET_PRODUCT_ITEM_STOCK = 'SET_PRODUCT_ITEM_STOCK'
export const SET_PRODUCT_ITEM_ARTICLE = 'SET_PRODUCT_ITEM_ARTICLE'
export const SET_PRODUCT_ITEM_IMAGE = 'SET_PRODUCT_ITEM_IMAGE'
export const SET_PRODUCT_ITEM_NAME = 'SET_PRODUCT_ITEM_NAME'

//Product Items Detail
export const SET_PRODUCT_ITEM_DETAIL_DATA = 'SET_PRODUCT_ITEM_DETAIL_DATA'
export const SET_PRODUCT_ITEM_DETAIL_LOADING = 'SET_PRODUCT_ITEM_DETAIL_LOADING'
export const SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY_PLUS = 'SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY_PLUS'
export const SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY_MINER = 'SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY_MINER'
export const SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY = 'SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY'

//Product Detail All
export const SET_PRODUCT_DETAIL_ALL_DATA = 'SET_PRODUCT_DETAIL_ALL_DATA'
export const SET_PRODUCT_DETAIL_ALL_LOADING = 'SET_PRODUCT_DETAIL_ALL_LOADING'

//Product Search
export const SET_PRODUCT_SEARCH_DATA = 'SET_PRODUCT_SEARCH_DATA'
export const SET_PRODUCT_SEARCH_LOADING = 'SET_PRODUCT_SEARCH_LOADING'

//User
export const SET_USER_IS_LOGIN = 'SET_USER_IS_LOGIN'
export const SET_USER_EMAIL = 'SET_USER_EMAIL'
export const SET_USER_MOBILE = 'SET_USER_MOBILE'
export const SET_USER_ID = 'SET_USER_ID'
export const SET_USER_FIRST_NAME = 'SET_USER_FIRST_NAME'
export const SET_USER_LAST_NAME = 'SET_USER_LAST_NAME'
export const SET_USER_TOKEN = 'SET_USER_TOKEN'
export const SET_RESET_PASSWORD_EMAIL = 'SET_RESET_PASSWORD_EMAIL'
export const SET_VERIFY_EMAIL_ADDRESS = 'SET_VERIFY_EMAIL_ADDRESS'
export const SET_EMAIL_IS_NULL = 'SET_EMAIL_IS_NULL'

//Shopping Carts
export const SET_SHOPPING_CART_UNCONFIRM_DATA  = ' SET_SHOPPING_CART_UNCONFIRM_DATA '
export const SET_SHOPPING_CART_UNCONFIRM_COUNT = 'SET_SHOPPING_CART_UNCONFIRM_COUNT'
export const SET_SHOPPING_CART_UNCONFIRM_TOTAL = 'SET_SHOPPING_CART_UNCONFIRM_TOTAL'