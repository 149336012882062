import { createStore, 
    applyMiddleware
 } from 'redux'
// import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import rootReducers from './Reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

const Store = createStore(
    rootReducers,
    composeWithDevTools(
       applyMiddleware(
           thunk,
            // createLogger()
       )
   )
)

export default Store