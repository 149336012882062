import React, { useState } from 'react'
import Axios from '../../Services/Axios'
import { Container, Table, Input, Button, InputGroup, Row, Spinner } from 'reactstrap' 
import { useParams } from 'react-router-dom'

const SearchStock = () => {
    const param = useParams()
    const [keyWord, setKeyWord] = useState(param.code)
    const [token] = useState('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZnVsbG5hbWUiOiJXZW5iaW4gV2FuZyIsImVtYWlsIjoiYmluZzEwMTNAaG90bWFpbC5jb20iLCJtb2JpbGUiOiIwODE5MDY2MjU5IiwibGV2ZWwiOjEsImlhdCI6MTU2Njk2Njg1MSwiZXhwIjoyNDMwOTY2ODUxfQ.KvW6drSCRs4iSNS_bSkPHqvxKiovB_vmJvPT96Kks_A')
    const [API_KEY] = useState('GjasFHdoj3as33E9uasGHKdjkLsd3ofRRtnoZSKkosadfdFH93h2b4UT95oa')
    const [stockOnly] = useState('N')
    const [stockData, setStockData] = useState([])
    const [searching, setSearching] = useState(false)
    const [stockTotal, setStockTotal] = useState(0)
 

    const onSearchStock = () => {
        setSearching(true)
        Axios({
            method: 'POST',
            url: '/searchstock',
            data: {
                P_SEARCH: keyWord,
                P_STOCKONLY: stockOnly,
                TOKEN: API_KEY
            },
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            const result = res.data.result
            // console.log(res.data.result)
            setStockData(result)
            let total = 0
            for (let i = 0; i < result.length; i++) {
                total += Number(result[i].TOTAL_QTY)
            }
            setStockTotal(total)
            setSearching(false)
        }).catch((err) => {
            console.log(err.data)
            setSearching(false)
        })
    }

    const numberFormat = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    return (
        <Container>
            <Row style={{ justifyContent: 'center' }} >
                <h1>Search Stock</h1>
            </Row>
            <Row>
                <InputGroup style={{ margin: 20 }} >
                    <Input placeholder='กรุณาใสรหัสสินค้า' defaultValue={keyWord} onChange={(e) => { setKeyWord(e.target.value) }} />
                    <Button outline onClick={() => { onSearchStock() }} >Search</Button>
                </InputGroup>
            </Row>
            <Row>
                <Table striped >
                    <thead style={{ backgroundColor: '#4d4d4d', color: '#fff' }} >
                        <tr>
                            <th>ARTICLE</th>
                            <th>NAME</th>
                            <th>QTY</th>
                        </tr>
                    </thead>
                    {searching ? (
                        <tbody>
                            <tr style={{ justifyContent: 'center' }} ><td></td><td><Spinner /></td><td></td></tr>
                        </tbody>
                    ) : (
                            <tbody>
                                {stockData.map((rows, index) => (
                                    <tr key={index} >
                                        <td>{rows.PRODUCT_CODE}</td>
                                        <td style={{ color: '#8c8c8c', fontSize: 14 }} >{rows.PRODUCT_NAME}</td>
                                        <td>{numberFormat(Number(rows.TOTAL_QTY).toFixed(2))} Y</td>
                                    </tr>))}
                            </tbody>)}
                    <tfoot style={{ backgroundColor: '#8c8c8c', color: '#fff' }} >
                        <tr>
                            <th></th>
                            <th>TOTAL : </th>
                            <th>{numberFormat(Number(stockTotal).toFixed(2))} Y</th>
                        </tr>
                    </tfoot>
                </Table>
            </Row>
        </Container>
    )
}

export default SearchStock
