import React, { useEffect, useState } from 'react'
import { Container, Breadcrumb, BreadcrumbItem, Col, Row, Card, CardFooter, CardImg, Spinner, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import axios from '../../Services/Axios'
import ImageUrl from '../../Services/ImageUrl'
import { Link, useParams } from 'react-router-dom'

import { connect } from 'react-redux'
import { setProductItemData, setProductItemName, setProductItemLoading, setProdcutItemStock, setProductItemArticle, setProductItemImage } from '../../Redux/Actions/ProductActions/ProductItemActions'



const ProductItem = ({ loading, stock, image, productItemData, name, setProductItemData, setProductItemName, setProductItemLoading, setProdcutItemStock, setProductItemArticle, setProductItemImage }) => {
    const [state, setState] = useState({
        P_COM: 'kp',
        P_USER: 'kpm',
        P_PRODUCT_CODE: null,
        P_PAGE: 1,
        isOpen: false
    })

    useEffect(() => {
        getProductItemData()
    }, [])

    const { article } = useParams()

    const getProductItemData = () => {
        setProductItemLoading(true)
        axios({
            mehtod: 'GET',
            url: `/pdi/${article}`
        }).then((res) => {
            setProductItemData(res.data.data[0])
            setProductItemLoading(false)
        }).catch((err) => {
            console.log(err)
        })
    }

    const toggle = () => {
        setState(prevState => ({
            isOpen: !prevState.isOpen
        }))
    }

    return (
        <Container>
            <Row>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to='/'>Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to='/products'>All Products</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>{article}</BreadcrumbItem>
                </Breadcrumb>
            </Row>
            <Row style={{ justifyContent: 'center' }} >
                {!loading ? productItemData.map((items, index) =>
                (
                    <Col key={index} sm={5} md={4} >
                        <Link to={'/productitemdetail/' + items.pdi_id}>
                            <Card style={{ marginBottom: 20, marginTop: 20 }}>
                                <CardImg
                                    src={ImageUrl.baseUrl + '/pdi/' + items.pdi_code + '/' + items.pdi_image}
                                    alt={items.pdi_name} />
                                <CardFooter>
                                    <p>{items.pdi_article}</p>
                                    <span>{items.pdi_name}</span>
                                </CardFooter>
                                {/* <Button style={{ margin: 5 }}
                                        onClick={async () =>
                                            axios({
                                                url: `/getstock`,
                                                method: 'POST',
                                                data: {
                                                    P_COM: state.P_COM,
                                                    P_USER: state.P_USER,
                                                    P_PRODUCT_CODE: items.pdi_article,
                                                    P_PAGE: state.P_PAGE
                                                }
                                            }).then((res) => {
                                                res.data.result.forEach((value, i) => {
                                                    setProductItemArticle(items.pdi_article)
                                                    setProdcutItemStock(value.TOTAL_QTY)
                                                    setProductItemImage(ImageUrl.baseUrl + '/pdi/' + items.pdi_code + '/' + items.pdi_image)
                                                    setProductItemName(items.pdi_name)
                                                    setState({ isOpen: true })
                                                })
                                            }).catch((err) => {
                                                console.log(err)
                                            })
                                        }>Check Stock</Button> */}
                            </Card>
                        </Link>
                    </Col>
                )) : (
                    <Row style={{ justifyContent: 'center', alignContent: 'center', marginTop: 100, marginBottom: 100 }} >
                        <Spinner type="grow" />
                    </Row>
                )}
            </Row>
            <Modal isOpen={state.isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <Row>
                        <Col>{article}</Col>
                    </Row>
                    <Row>
                        <Col>{name}</Col>
                    </Row>
                    <Row>
                        <Col>มีจำนวนในคลัง : {stock} YDS</Col>
                    </Row>
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardImg src={image} />
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    productItemData: state.productItem.productItemData,
    loading: state.productItem.loading,
    stock: state.productItem.stock,
    image: state.productItem.image,
    article: state.productItem.article,
    name: state.productItem.name
})

const mapDispatchToProps = (dispatch) => ({
    setProductItemData: (data) => {
        dispatch(setProductItemData(data))
    },
    setProductItemLoading: (loading) => {
        dispatch(setProductItemLoading(loading))
    },
    setProdcutItemStock: (stock) => {
        dispatch(setProdcutItemStock(stock))
    },
    setProductItemArticle: (article) => {
        dispatch(setProductItemArticle(article))
    },
    setProductItemImage: (image) => {
        dispatch(setProductItemImage(image))
    },
    setProductItemName: (name) => {
        dispatch(setProductItemName(name))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductItem)