import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const ContactUs = () => {
    return (
        <Container>
            <Row style={{ marginTop: 10, marginBottom: 10 }} >
                <Col style={{ borderBottomWidth: 2, borderBottomColor: 'grey' }} >
                    <h3>ติดต่อเรา</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>Tel : (+66) 2 432 0766-9 , (+66) 2 432 0646-9   </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>Fax : (+66) 2 432 0831-2  </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>Email : Sales@kpmtextile.com (Sales)</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>Email : Info@kpmtextile.com (Infomation)</p>
                </Col>
            </Row>
            <Row>
                <img style={{ width: 40, height: 40 }} alt='facebook' src='https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png' />
                <a href='https://fb.com/kpmtextile' target='_blank' style={{ margin: 7 }}  > <p>Follow Our Facebook Fan Page </p>
                </a>
            </Row>
            <Row>
                <img style={{ width: 40, height: 40 }} alt='line' src='https://www.freepnglogos.com/uploads/line-messenger-logo-social-media-png-3.png' />
                <a href='https://line.me/R/ti/p/%40vcj2903f' target='_blank' style={{ margin: 7 }}  >
                    <p>Follow Our Line Official Account</p>
                </a>
            </Row>
            <Row>
                <img style={{ width: 40, height: 40 }} src='https://cdn.iconscout.com/icon/free/png-256/google-map-461800.png' alt='google map' />
                <a style={{ margin: 7 }} target='_blank' href='https://goo.gl/maps/atcDhNLRAy79H6cV8' >
                    <p>Go To Google Maps</p>
                </a>
            </Row>
            <Row>
                <p>Address : 88 Ratchapruek 32, Ratchapruek Rd, Talingchan ,Talingchan, Bangkok 10170</p>
            </Row>
            <Row>
                <p>Address : 88 ราชพฤกษ์ ซ.32, ถนนราชพฤกษ์, แขวงตลิ่งชัน ,เขตลิ่งลัน, กรุงเทพมหานคร 10170</p>
            </Row>
        </Container>
    )
}

export default ContactUs