import { 
    SET_SHOPPING_CART_UNCONFIRM_DATA, 
    SET_SHOPPING_CART_UNCONFIRM_COUNT ,
    SET_SHOPPING_CART_UNCONFIRM_TOTAL
} from '../../Type'

export const setShoppingCartUnConfirmData = (data) => ({
    type: SET_SHOPPING_CART_UNCONFIRM_DATA,
    playload: data
})

export const setShoppingCartUnconfirmCount = (data) => ({
    type: SET_SHOPPING_CART_UNCONFIRM_COUNT,
    playload: data
})

export const setShoppingCartUnconfirmTotal = (data) =>({
    type : SET_SHOPPING_CART_UNCONFIRM_TOTAL,
    playload : data
})