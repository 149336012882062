import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Container, Button, Input, Card, CardBody, CardFooter, CardHeader, Spinner } from 'reactstrap'
import Axios from '../../Services/Axios'
import { setResetPasswordEmail } from '../../Redux/Actions/UserActions/UserActions'
import {useNavigate} from 'react-router-dom'

const ForgotPassword = (props) => {

    const [email, setEmail] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [sendding, setSendding] = useState(false)

    const navigate = useNavigate()

    const getVerifyCode = async () => {
        if (email === null) {
            setErrorMessage('กรุณาใส่ Email ของท่าน เพื่อ Reset Password')
        } else {
            setSendding(true)
            await Axios({
                url: '/forgotpassword',
                method: 'POST',
                data: {
                    email: email
                }
            }).then((res) => {
                props.setResetPasswordEmail(res.data.email)
                console.log(res)
                setErrorMessage('Virify Code Already Sent To Your Email ...')
                setTimeout(() => {
                    setSendding(false)
                    navigate('/resetpassword')
                }, 1000)
            }).catch((err) => {
                console.log(err)
                if (err.message === 'Request failed with status code 409') {
                    setErrorMessage('Email นี้ยังไม่ได้ลงทะเบียน !!!')
                } else if (err.message === 'Request failed with status code 500') {
                    setErrorMessage('เกิดข้อผิดพลาด !!!')
                } else {
                    setErrorMessage(err.message)
                }
            })
        }
    }

    return (
        <Container>
            <Card style={{ margin: 10 }} >
                <CardHeader>
                    Reset Your Password
                    </CardHeader>
                <CardBody>
                    <Input placeholder='Your Email' onChange={(e) => {
                        setEmail(e.target.value)
                        setErrorMessage(null)
                    }} />
                </CardBody>
                <CardFooter>
                    {sendding ? (<Spinner />) : (
                        <Button outline placeholder='Send Verify Code .' onClick={() => { getVerifyCode() }} >Send</Button>
                    )}
                    <p style={{ margin: 5, color: 'red' }} >{errorMessage}</p>
                </CardFooter>
            </Card>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    users: state.users
})

const mapDispatchToProps = (dispatch) => ({
    setResetPasswordEmail: (data) => {
        dispatch(setResetPasswordEmail(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)