import { SET_PRODUCT_DETAIL_DATA, SET_PRODUCT_DETAIL_LOADING } from '../../Type'

export const setProductDetailData = (data) => ({
    type: SET_PRODUCT_DETAIL_DATA,
    setData: data
})

export const setProductDetailLoading = (loading) => ({
    type: SET_PRODUCT_DETAIL_LOADING,
    setLoading: loading
})