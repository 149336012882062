import { SET_PRODUCT_DETAIL_DATA, SET_PRODUCT_DETAIL_LOADING } from '../../Type'

const productDetailReducers = (state = {
    productDetailData: [],
    loading: false
}, action) => {
    switch (action.type) {
        case SET_PRODUCT_DETAIL_DATA:
            state = {
                ...state,
                productDetailData: action.setData
            }
            break
        default:
        case SET_PRODUCT_DETAIL_LOADING:
            state = {
                ...state,
                loading: action.setLoading
            }
            break
    }
    return state
}

export default productDetailReducers