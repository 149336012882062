import React from 'react'
import { Jumbotron } from 'reactstrap'

const HomeJumboron = () => {
    return (
        <div style={{ margin: 20 }} >
            <Jumbotron style={{ color: 'grey' }} >
                <h4>ทำไมต้องเลือกผ้าตราม้าคู่</h4>
                <div>
                    <p>
                        <span style={{ color: 'red', fontSize: 20 }}>1. ผ้าตราม้าคู่มีผ้าให้เลือหลากหลาย</span>  มีชนิดผ้าให้เลือกรองรับงานดีไซน์-ตัดเย็บเสื้อผ้าแฟชั่นและสินค้าที่ทำจากผ้าได้มากกว่า 100 ชนิด มากกว่า 4000 สี มีทีมงานพร้อมรับสายให้ข้อมูลลูกค้าอย่างมืออาชีพ  ช่วยให้ดีไซน์เนอร์-การ์เม้นท์-ร้านเทเลอร์-ช่างตัดเสื้อและผู้ผลิตสินค้าแฟชั่นสามารถสร้างสรรค์แฟชั่นใหม่ๆ ออกแบบผลิตภัณฑ์สวยๆ เพิ่มมูลค่าผลิตภัณฑ์สร้างสรรค์ได้อย่างไม่สิ้นสุด
                    </p>
                    <p>
                        <span style={{ color: 'red', fontSize: 20 }}>2. ผ้าตราม้าคู่คุณภาพเยี่ยมสุด ผ้าสีสวยสีผ้าไม่ตก</span>  เพื่อคุณภาพที่ดีของแฟชั่น เสื้อผ้า กระเป๋าผ้า หมวกผ้า ชุดเอี๊ยม ชุดยูนิฟอร์มและของใช้อื่นๆ ที่ทำจากผ้า สร้างชื่อเสียงที่ดีให้กับเจ้าของแบรนด์แฟชั่น ไม่สร้างความรำคาญให้ผู้ใช้
                    </p>
                    <p>
                        <span style={{ color: 'red', fontSize: 20 }}> 3. ผ้าตราม้าคู่มีสต๊อกเยอะ </span>  เรามีสต็อกผ้ารองรับออเดอร์ใหญ่-เล็ก ลูกค้าสั่งซื้อผ้าได้ไม่มีขึ้นต่ำ บริการสำรองผ้า Cotton, TR, TC Polyester พร้อมจัดส่งโดยที่ลูกค้าไม่ต้องสั่งผลิตและรอนานเป็นเดือนๆ ตอบโจทย์สายการผลิตโรงงานการ์เม้นท์ งานเทเลอร์ตัดชุดยูนิฟอร์ม ไม่สะดุด-เดินสายการผลิตได้อย่างราบรื่น ส่งมอบงานให้ลูกค้าได้ตรงเวลา สามารถเช็คจำนวนสต๊อกหน้าเว็บได้เลย
                    </p>
                    <p>
                        <span style={{ color: 'red', fontSize: 20 }}>4. ผ้าตราม้าคู่บริการไวดี</span>  เราบริหารจัดการด้านการจัดส่งอย่างมืออาชีพ จัดส่งมอบผ้าตราม้าคู่ ทั้งในพื้นที่กรุงเทพ นนทบุรี นครปฐม สมุทรสาคร ปทุมธานี สมุทรปราการ และจัดส่งทั่วประเทศได้อย่างรวดเร็ว
                    </p>
                </div>
            </Jumbotron>
        </div>
    )
}

export default HomeJumboron
