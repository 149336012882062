import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Card, CardFooter, CardImg, Spinner } from 'reactstrap'
import axios from '../../Services/Axios'
import ImageUrl from '../../Services/ImageUrl'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { setProductGroupData, setProductGroupLoading } from '../../Redux/Actions/ProductActions/ProductGroupActions'

const ProductGroup = ({ setProductGroupData, setProductGroupLoading,loading,productGroupData }) => {

    useEffect(() => {
        getProductGroupData()
    }, [])

    const getProductGroupData = async () => {
        setProductGroupLoading(true)
        axios({
            mehtod: 'GET',
            url: '/pdg'
        }).then((res) => {
            setProductGroupData(res.data.data)
            setProductGroupLoading(false)

        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <Container>
            <Row  >
                {!loading ? productGroupData.map((items, index) => (
                    <Col key={index} sm={5} md={4} >
                        <Link to={`/productdetail/` + items.pdg_id}>
                            <Card style={{ marginBottom: 20, marginTop: 20 }} >
                                <CardImg src={ImageUrl.baseUrl + '/pdg/' + items.pdg_image} />
                                <CardFooter>{items.pdg_name}</CardFooter>
                            </Card>
                        </Link>
                    </Col>
                )) : (
                    <Row style={{ justifyContent: 'center', alignContent: 'center', marginBottom: 100, marginTop: 100 }} >
                        <Spinner type="grow" />
                    </Row>
                )}
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    productGroupData: state.productGroup.productGroupData,
    loading: state.productGroup.loading
})
const mapDispatchToProp = (dispatch) => ({
    setProductGroupData: (data) => {
        dispatch(setProductGroupData(data))
    },
    setProductGroupLoading: (loading) => {
        dispatch(setProductGroupLoading(loading))
    }
})
export default connect(mapStateToProps, mapDispatchToProp)(ProductGroup)