import React, { useState } from 'react'
import { Container, Card, CardHeader, CardBody, CardFooter, Button, Input, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { setUserEmail, setUserId, setUserIsLogin, setUserMobile, setUserLastName, setUserFirstName, setUserToken } from '../../Redux/Actions/UserActions/UserActions'
import Axios from '../../Services/Axios'
import { Link } from 'react-router-dom'


const SignIn = (props) => {
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [message, setMessage] = useState(null)
    // console.log(props)

    const onLogin = async () => {
        if (email == null || password == null) {
            alert('Please Type Your Email Or Password')
        } else {
            await Axios({
                method: 'POST',
                url: '/signin',
                data: {
                    email: email,
                    password: password
                }
            }).then((res) => {
                // console.log(res)
                if (res.status === 200) {
                    props.setUserIsLogin(true)
                    props.setUserEmail(res.data.data.email)
                    props.setUserId(res.data.data.user_id)
                    props.setUserMobile(res.data.data.mobile)
                    props.setUserFirstName(res.data.data.first_name)
                    props.setUserLastName(res.data.data.last_name)
                    props.setUserToken(res.data.token)
                    setMessage(res.data.message)
                    localStorage.setItem('KPM_WEB_TOKEN', res.data.token)
                    setTimeout(() => {
                        props.history.goBack()
                    }, 500)
                } else {
                    props.setUserIsLogin(false)
                }
            }).catch((err) => {
                console.log(err)
                setErrorMessage('Email Or Password Incrrect')
            })
        }
    }


    return (
        <Container>
            <Card style={{ margin: 20 }} >
                <CardHeader>
                    User Login
                </CardHeader>
                <CardBody>
                    <Input type='text' style={{ margin: 5 }} placeholder='Email Or Mobile Number' onChange={(e) => {
                        setEmail(e.target.value)
                        setErrorMessage('')
                    }} />
                    <Input type='password' style={{ margin: 5 }} placeholder='Password ***' onChange={(e) => {
                        setPassword(e.target.value)
                        setErrorMessage('')
                    }} />
                </CardBody>
                <CardFooter >
                    <p style={{ color: 'red' }} >{errorMessage}</p>
                    <p style={{ color: 'red' }} >{message}</p>
                    <Row>
                        <Button outline style={{ margin: 5 }} onClick={() => { onLogin() }} >Login</Button>
                        <Button outline style={{ margin: 5 }} onClick={() => { props.history.push('/') }} >Cancel</Button>
                    </Row>
                    <Row>
                        <Link style={{ margin: 5 }} to='/signup' >I don't have an account , Go to register page ...</Link>
                    </Row>
                    <Row>
                        <Link to='/forgotpassword' style={{ margin: 5 }}>Forgot your password!! Reset your password ...</Link>
                    </Row>
                </CardFooter>
            </Card>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    users: state.users
})

const mapDispatchToProps = (dispatch) => ({
    setUserIsLogin: (data) => {
        dispatch(setUserIsLogin(data))
    },
    setUserEmail: (data) => {
        dispatch(setUserEmail(data))
    },
    setUserMobile: (data) => {
        dispatch(setUserMobile(data))
    },
    setUserId: (data) => {
        dispatch(setUserId(data))
    },
    setUserLastName: (data) => {
        dispatch(setUserLastName(data))
    },
    setUserFirstName: (data) => {
        dispatch(setUserFirstName(data))
    },
    setUserToken: (data) => {
        dispatch(setUserToken(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)