import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import Axios from '../../Services/Axios'
import { Container, Row, Col, Spinner } from 'reactstrap'

const ScanStock = () => {
    const [token] = useState('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZnVsbG5hbWUiOiJXZW5iaW4gV2FuZyIsImVtYWlsIjoiYmluZzEwMTNAaG90bWFpbC5jb20iLCJtb2JpbGUiOiIwODE5MDY2MjU5IiwibGV2ZWwiOjEsImlhdCI6MTU2Njk2Njg1MSwiZXhwIjoyNDMwOTY2ODUxfQ.KvW6drSCRs4iSNS_bSkPHqvxKiovB_vmJvPT96Kks_A')
    const [API_KEY] = useState('GjasFHdoj3as33E9uasGHKdjkLsd3ofRRtnoZSKkosadfdFH93h2b4UT95oa')
    const [stockOnly] = useState('N')
    const [loading, setLoading] = useState(false)
    const [searchResult, setSearchResult] = useState(null)
    const [param] = useSearchParams()
    const article = param.get('article')

    useEffect(() => {
        setLoading(true)
        Axios({
            method: 'POST',
            url: '/scanstock',
            data: {
                P_SEARCH: article,
                P_STOCKONLY: stockOnly,
                TOKEN: API_KEY
            },
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then(({ data }) => {
            console.log(data.result) 
            setSearchResult(data.result)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
            alert('Error!!! Something wrong with connections.')
        })
    }, [])
    const numberFormat = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    return (
        <Container>
            <Row>
                {loading ?
                    <Col style={{ display: 'flex',backgroundColor:'#f2f2f2',padding:5,borderRadius:10, justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                        <Spinner type="grow" />
                    </Col>
                    : searchResult === null ? <h3>No Found</h3> : searchResult.map((rows, index) => (
                        <Col key={index} style={{ display: 'flex',backgroundColor:'#f2f2f2',padding:5,borderRadius:10, justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 20, marginBottom: 20 }} >
                            <p style={{ fontWeight: 'bold', fontSize: 18 }}>{rows.PRODUCT_CODE}</p>
                            <p>{rows.PRODUCT_NAME}</p>
                            <p style={{ fontWeight: 'bold', fontSize: 18, color: 'red' }}>{numberFormat(rows.TOTAL_QTY)}YDS</p>
                        </Col>))}
            </Row>
        </Container>
    )
}

export default ScanStock