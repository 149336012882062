import { 
    SET_PRODUCT_ITEM_DETAIL_DATA, 
    SET_PRODUCT_ITEM_DETAIL_LOADING, 
    SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY_PLUS, 
    SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY_MINER,
    SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY
} from '../../Type'

const productItemDetailReducers = (state = {
    productItemDetailData: [],
    productItemDetailLoading: false,
    bookingQty: 0
}, action) => {
    switch (action.type) {
        case SET_PRODUCT_ITEM_DETAIL_DATA:
            state = {
                ...state,
                productItemDetailData: action.playload
            }
            break
        case SET_PRODUCT_ITEM_DETAIL_LOADING:
            state = {
                ...state,
                productItemDetailLoading: action.playload
            }
            break
        case SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY_PLUS:
            state = {
                ...state,
                bookingQty: state.bookingQty + action.playload
            }
            break
        case SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY_MINER:
            state = {
                ...state,
                bookingQty: state.bookingQty - action.playload
            }
            break
        case SET_PRODUCT_ITEM_DETAIL_BOOKING_QTY:
            state={
                ...state,
                bookingQty:action.playload
            }
            break
        default:
    }
    return state
}

export default productItemDetailReducers