import { SET_PRODUCT_GROUP_DATA, SET_PRODUCT_GROUP_LOADING } from '../../Type'

const productGroupReducers = (state = {
    productGroupData: [],
    loading: false
}, action) => {
    switch (action.type) {
        case SET_PRODUCT_GROUP_DATA:
            state = {
                ...state,
                productGroupData: action.setData
            }
            break
        default:
        case SET_PRODUCT_GROUP_LOADING:
            state = {
                ...state,
                loading: action.setLoading
            }
    }
    return state
}

export default productGroupReducers