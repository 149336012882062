import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.min.css'
//import './index.css'
import { Provider } from 'react-redux'
import Store from './Redux/Store'
import WebFont from 'webfontloader';

WebFont.load({
    google: {
        families: ['Prompt', 'sans-serif']
    }
});  


ReactDOM.render(
    <Provider store={Store} >
        <App />
    </Provider>
    , document.getElementById('root'))

  
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
