import React, { useState } from 'react'
import { Container, Card, CardBody, CardHeader, CardFooter, Input, Button, Row, Spinner } from 'reactstrap'
import Axios from '../../Services/Axios'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { setVerifyEmailAddress,setEmailIsNull } from '../../Redux/Actions/UserActions/UserActions'

const SignUp = (props) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [registering, setRegistering] = useState(false)

    const onRegisterUser = () => {
        if (
            firstName === '' ||
            lastName === '' ||
            email === '' ||
            password === '' ||
            password !== rePassword) {
            setErrorMessage('กรุณากรอกข้อมูลครบถ้วน')
        } else {
            setRegistering(true)
            Axios({
                method: 'POST',
                url: '/signup',
                data: {
                    id: null,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    mobile: mobile,
                    password: rePassword,
                    status: 0,
                    userLevel: 1
                }
            }).then((res) => {
                // console.log(res)
                if (res.status === 201) {
                    setMessage('ลงทะเบียนสำเร็จ')
                    setTimeout(() => {
                        props.setVerifyEmailAddress(email)
                        props.setEmailIsNull(false)
                        setRegistering(false)
                        props.history.push('/verifyuser')
                    }, 1000)
                } else {
                    setErrorMessage('ลงทะเบียนไม่สำเร็จ')
                }
            }).catch((err) => {
                setRegistering(false)
                console.error(err.message)
                if (err.message === 'Request failed with status code 409') {
                    setErrorMessage('ลงทะเบียนไม่สำเร็จ คุณมีบัญชีแล้ว ...')
                } else {
                    setErrorMessage('ลงทะเบียนไม่สำเร็จ การเชื่อมต่อเกิดการผิดพลาด')
                }
            })
        }
    }

    return (
        <Container>
            <Card style={styles.form} >
                <CardHeader>
                    User Register ...
                </CardHeader>
                <CardBody>
                    <Input type='text' style={styles.input} placeholder='Fist Name' onChange={(e) => {
                        setFirstName(e.target.value)
                        setErrorMessage('')
                    }} />
                    <Input type='text' style={styles.input} placeholder='Last Name' onChange={(e) => {
                        setLastName(e.target.value)
                        setErrorMessage('')
                    }} />
                    <Input type='text' style={styles.input} placeholder='Email' onChange={(e) => {
                        setEmail(e.target.value)
                        setErrorMessage('')
                    }} />
                    <Input type='text' style={styles.input} placeholder='Mobile' onChange={(e) => {
                        setMobile(e.target.value)
                        setErrorMessage('')
                    }} />
                    <Input type='password' style={styles.input} placeholder='Password' onChange={(e) => {
                        setPassword(e.target.value)
                        setErrorMessage('')
                    }} />
                    <Input type='password' style={styles.input} placeholder='Retype-Password' onChange={(e) => {
                        setRePassword(e.target.value)
                        setErrorMessage('')
                    }} />
                </CardBody>
                <CardFooter>
                    <p style={{ color: 'red' }} >{message}</p>
                    <p style={{ color: 'red' }} >{errorMessage}</p>
                    <Row>
                        {registering ? (
                           <Spinner  />
                        ) : (
                            <Button outline style={styles.button} onClick={() => { onRegisterUser() }} >Register Now </Button>
                        )}
                        <Button outline style={styles.button} onClick={() => { props.history.push('/') }} >Cancel</Button>
                    </Row>
                    <Row>
                        <Link style={styles.button} to='/signin' >I aready have an account , Go to sign in page ...</Link>
                    </Row>
                    <Row>
                        <Link style={styles.button} to='/verifyuser'>Verify Users</Link>
                    </Row>
                </CardFooter>
            </Card>
        </Container>
    )
}

const styles = {
    form: {
        margin: 15
    },
    input: {
        margin: 5
    },
    button: {
        margin: 10
    }
}

const mapStateToProps = (state) => ({
    users: state.users
})

const mapDispatchToProps = (dispatch)=>({
    setVerifyEmailAddress:(data)=>{
        dispatch(setVerifyEmailAddress(data))
    },
    setEmailIsNull: ((data) => {
        dispatch(setEmailIsNull(data))
    })
})

export default connect(mapStateToProps,mapDispatchToProps)(SignUp)