import React, { useState } from 'react'
import { Container, Card, CardHeader, CardBody, CardFooter, Input, Button, Spinner } from 'reactstrap'
import Axios from '../../Services/Axios'
import { connect } from 'react-redux'
import {useNavigate} from 'react-router-dom'

const ResetPassword = (props) => {
    const [verifyCode, setVerifyCode] = useState(null)
    const [password, setPassword] = useState(null)
    const [rePassword, setRePassword] = useState(null)
    const [message, setMessage] = useState(null)
    const [resetting, setResetting] = useState(false)

    const navigate = useNavigate()

    const onResetPassword = async () => {
        if (verifyCode === null || password === null || rePassword === null) {
            setMessage('กรุณณากรอกข้อมูล')
        } else if (password !== rePassword) {
            setMessage('กรุณาใส่ Password 2 ช่องให้ถูกต้อง')
        } else {
            setResetting(true)
            await Axios({
                method: 'POST',
                url: '/resetpassword',
                data: {
                    email: props.users.resetPasswordEmail,
                    verifyCode: verifyCode,
                    newPassword: rePassword
                }
            }).then((res) => {
                // console.log(res)
                setMessage('Reset Password Successful ...')
                alert('Reset Password Successful ...')
                setTimeout(() => {
                    setResetting(false)
                    navigate('/signin')
                }, 1000)
            }).catch((err) => {
                console.log(err)
                setMessage('Reset Password Error !!!')
                setResetting(false)
            })
        }
    }
    // console.log(props.users)
    return (
        <Container>
            <Card>
                <CardHeader>
                    Reset Your Password
                </CardHeader>
                <CardBody>
                    <Input style={{ margin: 10 }} type='number' onChange={(e) => { setVerifyCode(e.target.value) }} placeholder='Verify Code , Get the code from your email...' />
                    <Input style={{ margin: 10 }} type='password' onChange={(e) => { 
                        setPassword(e.target.value)
                        setMessage(null) }} placeholder='New Password' />
                    <Input style={{ margin: 10 }} type='password' onChange={(e) => { 
                        setRePassword(e.target.value) 
                        setMessage(null)
                        }} placeholder='Re-Type New Password' />
                </CardBody>
                <CardFooter>
                    {resetting ? (<Spinner />) : (
                        <Button outline onClick={() => { onResetPassword() }} >Reset Password Now...</Button>
                    )}
                    <p style={{ color: 'red', margin: 10 }} >{message}</p>
                </CardFooter>
            </Card>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    users: state.users
})

export default connect(mapStateToProps)(ResetPassword)