import React, { useState, useEffect } from 'react'
import { Input, Row, Col, Button, InputGroup, Container, Card, CardFooter, CardImg, Spinner, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { FaSearch } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ImageUrl from '../../Services/ImageUrl'
import axios from '../../Services/Axios'
import { connect } from 'react-redux'
import { setProductSearchData, setProductSearchLoading } from '../../Redux/Actions/ProductActions/ProductSearchActions'

const ProductSearch = (props) => {
    const [keyword, setKeyword] = useState('%')

    useEffect(() => {
        getProductSearchData()
    }, [keyword])

    const getProductSearchData = async () => {
        props.setProductSearchLoading(true)
        axios({
            url: '/search',
            method: 'POST',
            data: {
                keyword: keyword
            }
        }).then((res) => {
            props.setProductSearchData(res.data.data)
            props.setProductSearchLoading(false)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <Container>
            <Row style={{ marginTop: 5 }}>
                <Col style={{ left: '50%', right: '0', maxWidth: '50%' }} >
                    <InputGroup>
                        <Input placeholder='Search Products'
                            onChange={(e) => {
                                setKeyword(e.target.value)
                            }}
                            autoFocus={true}
                            style={{ backgroundColor: '#f2f2f2', borderRadius: 5, }} />
                        <Button onClick={() => { getProductSearchData() }} >
                            <FaSearch name='search' />
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Breadcrumb >
                    <BreadcrumbItem>
                        <Link to='/'>Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to='/product'>Categories</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to='/products'>All Products</Link>
                    </BreadcrumbItem>
                </Breadcrumb>
            </Row>
            <Row style={{ justifyContent: 'center' }} >
                {!props.loading ? props.productSearchData.map((items, index) => (
                    <Col key={index} sm={5} md={4} >
                        <Link to={`/productitem/` + items.pdd_code}>
                            <Card style={{ marginBottom: 20, marginTop: 20 }} >
                                <CardImg src={ImageUrl.baseUrl + '/pdd/' + items.pdd_img} />
                                <CardFooter>{items.pdd_name}</CardFooter>
                            </Card>
                        </Link>
                    </Col>
                )) : (
                        <Row style={{ marginTop: 100, marginBottom: 100 }} >
                            <Spinner type="grow" Rowor="primary" />
                        </Row>
                    )}
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    productSearchData: state.productSearch.productSearchData,
    loading: state.productSearch.loading
})

const mapDispatchToProps = (dispatch) => ({
    setProductSearchData: (data) => {
        dispatch(setProductSearchData(data))
    },
    setProductSearchLoading: (loading) => {
        dispatch(setProductSearchLoading(loading))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch)