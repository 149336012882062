import React from 'react'
import { Container, Row, Card, CardImg } from 'reactstrap'
import { FaPhone, FaMailBulk, FaLine, FaFacebook } from 'react-icons/fa'
import { RiEarthLine } from 'react-icons/ri'
import ContactForm from '../Home/ContactForm'
import OurProducts from '../Home/OurProducts'

const SalesPage = (props) => {
    return (
        <Container style={{ fontFamily: 'Prompt' }}>
            <Row style={{ marginTop: 10 }} className='justify-content-center' >
                <Card style={{ padding: 10, borderRadius: 90, margin: 10 }}>
                    <CardImg style={{ width: 160, borderRadius: 80 }} src={'https://media.yellowpages.co.th/yellowpages/logo/5d89d31e5c65c-icon?itok=5ee30c60'} />
                </Card>
            </Row>
            <div className='justify-content-center' >
                <Row className='justify-content-center' ><h3>ผ้าตราม้าคู่ เค.พี.เอ็ม เท็กซ์ไทล์</h3></Row>
                <Row className='justify-content-center' >
                    <p>
                        <FaPhone style={{ fontSize: 14 }} />  <a href='tel:024320766'>024320766-9</a> , <a href='tel:024320646'>024320646-9</a>
                    </p>
                </Row>
                <Row className='justify-content-center' >
                    <p>
                        <FaMailBulk style={{ fontSize: 14 }} /> <a href='mailto:sales@kpmtextile.com'>sales@kpmtextile.com</a>
                    </p>
                </Row>
                <Row className='justify-content-center' >
                    <a style={{ margin: 15 }} href='https://fb.com/kpmtextile' ><FaFacebook style={{ fontSize: 28 }} /></a>
                    <a style={{ margin: 15 }} href='https://lin.ee/fnVRtge' ><FaLine style={{ fontSize: 28, color: 'green' }} /></a>
                    <a style={{ margin: 15 }} href='https://kpmtextile.com' ><RiEarthLine style={{ fontSize: 28, color: '#000' }} /></a>
                </Row>
            </div>
            <div style={{ padding: 10, backgroundColor: '#f2f2f2', marginTop: 20 }} >
                <h4 >เกี่ยวกับเรา</h4>
                <div className='justify-content-around' >
                    <p>บริษัท เค.พี.เอ็ม เท็กซ์ไทล์ จำกัด  ก่อตั้งเมื่อ 20 มิ.ย. 2543 ( 20 JUNE 2000​ ) เราเป็นผู้เชี่ยวชาญในวงการสิ่งทอเป็นเวลา 20 ปี</p>
                    <p>ภายใต้แบรน ผ้าตราผ้าคู่</p>
                    <p>ที่ตั้งปัจุบันอยู่ที่ : 88 ถนนราชพฤกษ์ แขวง/เขต ตลิ่งชัน กรุงเทพ 10170</p>
                    <p>ขายส่ง และ ขายปลีก</p>
                    <p>จำหน่ายผ้า มากกว่า 100 ชินิด เช่น  ผ้าคอตตอน ผ้าคอมทวิว ผ้าแคนวาส ผ้าเสิร์ท ผ้าเวสปอยท์ ผ้าซาติน ผ้าดอบบี้ ผ้าป๊อปปลิ้น ผ้าปิเก้ ผ้ายืดสเปนเดกซ์ ผ้าลาเฟอร์พีช ผ้ายีนส์ขาวลีวาย ผ้าบลูยีนส์ ผ้าตัดชุดยูนิฟอร์ม ผ้าตัดเสื้อผ้าแฟชั่น วัตถุดิบผ้าสำหรับผลิต เสื้อกางเกงแฟชั่นชาย-หญิง ชุดเครื่องแบบนักเรียน-ชุดนิสิตนักศึกษา ผ้าลายพรางตัดชุดเครื่องแบบทหารบก เสื้อโปโล-เสื้อโฆษณา ชุดเสื้อผ้าเด็ก สำหรับโรงงานการ์เม้นท์ ร้านเทเลอร์ ร้านเสื้อผ้ากีฬา</p>
                </div>
                <div style={{ textAlign: 'center', width: '100%', left: 0, right: 0 }}>
                    <CardImg src='https://kpmtextile.com/service/uploads/image/salespage/headoffice.jpg' style={{ width: '40%' }} />
                </div>
            </div>
            <div style={{ padding: 10, backgroundColor: '#f2f2f2', marginTop: 20 }}>
                <h4>ทำไมต้องเลือกผ้าตราผ้าคู่</h4>
                <div>
                    <p>
                        <span style={{ color: 'red', fontSize: 20 }}>1. ผ้าตราม้าคู่มีผ้าให้เลือหลากหลาย</span>  มีชนิดผ้าให้เลือกรองรับงานดีไซน์-ตัดเย็บเสื้อผ้าแฟชั่นและสินค้าที่ทำจากผ้าได้มากกว่า 100 ชนิด มากกว่า 4000 สี มีทีมงานพร้อมรับสายให้ข้อมูลลูกค้าอย่างมืออาชีพ  ช่วยให้ดีไซน์เนอร์-การ์เม้นท์-ร้านเทเลอร์-ช่างตัดเสื้อและผู้ผลิตสินค้าแฟชั่นสามารถสร้างสรรค์แฟชั่นใหม่ๆ ออกแบบผลิตภัณฑ์สวยๆ เพิ่มมูลค่าผลิตภัณฑ์สร้างสรรค์ได้อย่างไม่สิ้นสุด
                   </p>
                    <p>
                        <span style={{ color: 'red', fontSize: 20 }}>2. ผ้าตราม้าคู่คุณภาพเยี่ยมสุด ผ้าสีสวยสีผ้าไม่ตก</span>  เพื่อคุณภาพที่ดีของแฟชั่น เสื้อผ้า กระเป๋าผ้า หมวกผ้า ชุดเอี๊ยม ชุดยูนิฟอร์มและของใช้อื่นๆ ที่ทำจากผ้า สร้างชื่อเสียงที่ดีให้กับเจ้าของแบรนด์แฟชั่น ไม่สร้างความรำคาญให้ผู้ใช้
                   </p>
                    <p style={{ textAlign: 'center', width: '100%', left: 0, right: 0 }}>
                        <CardImg src='https://kpmtextile.com/service/uploads/image/salespage/hanger.jpg' style={{ width: '60%' }} />
                    </p>
                    <p>
                        <span style={{ color: 'red', fontSize: 20 }}> 3. ผ้าตราม้าคู่มีสต๊อกเยอะ </span>  เรามีสต็อกผ้ารองรับออเดอร์ใหญ่-เล็ก ลูกค้าสั่งซื้อผ้าได้ไม่มีขึ้นต่ำ บริการสำรองผ้า Cotton, TR, TC Polyester พร้อมจัดส่งโดยที่ลูกค้าไม่ต้องสั่งผลิตและรอนานเป็นเดือนๆ ตอบโจทย์สายการผลิตโรงงานการ์เม้นท์ งานเทเลอร์ตัดชุดยูนิฟอร์ม ไม่สะดุด-เดินสายการผลิตได้อย่างราบรื่น ส่งมอบงานให้ลูกค้าได้ตรงเวลา สามารถเช็คจำนวนสต๊อกหน้าเว็บได้เลย
                   </p>
                    <p style={{ textAlign: 'center', width: '100%', left: 0, right: 0 }}>
                        <CardImg src='https://kpmtextile.com/service/uploads/image/salespage/warehouse.jpg' style={{ width: '60%' }} />
                    </p>
                    <p>
                        <span style={{ color: 'red', fontSize: 20 }}>4. ผ้าตราม้าคู่บริการไวดี</span>  เราบริหารจัดการด้านการจัดส่งอย่างมืออาชีพ จัดส่งมอบผ้าตราม้าคู่ ทั้งในพื้นที่กรุงเทพ นนทบุรี นครปฐม สมุทรสาคร ปทุมธานี สมุทรปราการ และจัดส่งทั่วประเทศได้อย่างรวดเร็ว
                </p>
                    <p style={{ textAlign: 'center', width: '100%', left: 0, right: 0 }}>
                        <CardImg src='https://kpmtextile.com/service/uploads/image/salespage/truck.jpg' style={{ width: '60%' }} />
                    </p>
                </div>
            </div>
            <div style={{ padding: 10, backgroundColor: '#f2f2f2', marginTop: 20 }}>
                <OurProducts />
            </div>
            <div style={{ padding: 10, backgroundColor: '#f2f2f2', marginTop: 20 }} >

            </div>
            <div style={{ padding: 10, backgroundColor: '#f2f2f2', marginTop: 20 }}>
                <h4>การเดินทาง</h4>
                <p>เดินทางมาเยี่ยมชมสินค้าของจริง มีผ้าตัดชุดหลากหลายทั้งผ้าลายดอกและผ้าพื้นให้เลือกจุใจไม่เสียเที่ยว เลือกซื้อผ้าที่ชอบติดมือกลับได้อย่างสุขใจ โชว์รูมผ้าตราม้าคู่ อยู่ปากซอย ราชพฤกษ์32 ตรงข้ามหมู่บ้านลัดดารมย์ มาจากถนนบรมราชชนนี เข้าถนนราชพฤกษ์ (ฝั่งทางไปวงเวียนพระราม 5) ระยะทาง 1 กม. ชิดซ้ายหน้าโตโยต้า BUZZ กลับรถใต้สะพานข้ามคลองมหาสวัสดิ์ ตลิ่งชัน ผ่านวัดคริสต์ ศีลมหาสนิท ถึงโชว์รูมผ้าตราม้า จอดรถหน้าร้านได้เลย</p>
                <div style={{ textAlign: 'center', width: '100%', left: 0, right: 0 }} >
                    <CardImg src='https://kpmtextile.com/service/uploads/image/salespage/kpmlogo.jpg' style={{ width: '60%' }} />
                </div>
            </div>
            <ContactForm />
        </Container>
    )
}


export default SalesPage
