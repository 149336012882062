import { SET_PRODUCT_GROUP_DATA, SET_PRODUCT_GROUP_LOADING } from '../../Type'

export const setProductGroupData = (data) => ({
    type: SET_PRODUCT_GROUP_DATA,
    setData: data
})

export const setProductGroupLoading = (loading) => ({
    type: SET_PRODUCT_GROUP_LOADING,
    setLoading: loading
})