import React from 'react'
import { Input, Row, Col, Button, InputGroup } from 'reactstrap'
import { FaSearch } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const SearchBar = () => {
    const navigate = useNavigate()
    return (
        <Row>
            <Col style={{ left: '50%', right: '10%', maxWidth: '50%' }} >
                <InputGroup>
                    <Input placeholder='Search Products'
                        onFocus={() => { navigate('/productsearch') }}
                        style={{ backgroundColor: '#f2f2f2', borderRadius: 5, }} />
                    <Button>
                        <FaSearch name='search' />
                    </Button>
                </InputGroup>
            </Col>
        </Row>
    )
}
export default SearchBar