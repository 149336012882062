import React, { useState, useEffect } from 'react'
import { Table, Container, Input, InputGroupAddon, InputGroup, Spinner, Button, Row } from 'reactstrap'
import Axios from '../../Services/Axios'
import dayjs from 'dayjs'
import { FaSearch } from 'react-icons/fa'
// import DataTable from 'react-data-table-component'

const Tracking = () => {

    const [state, setState] = useState({
        trackingData: [],
        keyword: '%',
        isLoading: true
    })

    useEffect(() => {
        getTrackingAll()
    }, [])

    const getTrackingAll = async () => {
        await Axios({
            method: 'POST',
            url: '/gettrackingall',
            data: {
                keyword: state.keyword
            }
        }).then((res) => {
            setState({
                trackingData: res.data.data[0],
                isLoading: false
            })
        }).catch((err) => { console.log(err) })
    }

    return (
        <Container>
            <Row style={{ margin: 10 }} className='justify-content-center' >
                <h3>ผ้าตราม้าคู่ รายการส่งสินค้า</h3>
            </Row>
            <Row>
                <InputGroup>
                    <Input placeholder="กรุณาใส่ชื่อใส่ช่องนี้" onChange={(e) => {
                        setState({ keyword: e.target.value })
                    }} />
                    <InputGroupAddon addonType="append">
                        <Button onClick={getTrackingAll} >
                            <FaSearch />
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </Row>
            <Row>
                <Table hover bordered striped responsive >
                    <thead>
                        <tr>
                            <th>ชื่อผู้รับ/วันที่</th>
                            <th>Tracking No.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!state.isLoading ? state.trackingData.map((rows, index) => (
                            <tr key={index} >
                                <td>
                                    <div>{rows.receive_name}</div>
                                    <div style={{ fontSize: 12, color: 'grey' }} >{dayjs(rows.ship_date).format('DD/MM/YYYY')}</div>
                                </td>
                                <td>
                                    <div>{rows.tracking_no}</div>
                                    <div style={{ fontSize: 12, color: 'grey' }} >{rows.ship_by}</div>
                                </td>
                            </tr>)) : (
                            <tr  >
                                <td>
                                    <Spinner />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Row>
        </Container>
    )
}

export default Tracking