import { SET_PRODUCT_SEARCH_DATA, SET_PRODUCT_SEARCH_LOADING } from '../../Type'

const productSearchReducers = (state = {
    productSearchData: [],
    loading: false
}, action) => {
    switch (action.type) {
        case SET_PRODUCT_SEARCH_DATA:
            state = {
                ...state,
                productSearchData: action.setData
            }
            break
        default:
        case SET_PRODUCT_SEARCH_LOADING:
            state = {
                ...state,
                loading: action.setLoading
            }
            break
    }
    return state
}

export default productSearchReducers