import React, { useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Container, Row, CardImg } from 'reactstrap'
import classnames from 'classnames'
import MainTabs from './MainTab'
import SecondTabs from './SecondTab'
import { connect } from 'react-redux'

const Profiles = (props) => {
  const [activeTab, setActiveTab] = useState('2')
  const [profilePic] = useState(null)

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  return (
    <Container>
      <Row className='justify-content-center' >
        <div style={{ margin: 10 }} >
          {profilePic === null ? (
            <CardImg style={{ width: 150, height: 150, borderRadius: 75 }} src='../../noperson.png' />
          ) : (
              <CardImg />
            )}
        </div>
      </Row>
      <Row className='justify-content-center' >
          <h4>{props.users.userFirstName + ' ' + props.users.userLastName}</h4>
      </Row>
      <Row className='justify-content-center' >
          <p>{props.users.userEmail}</p>
        </Row>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >Main</NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >My Order History</NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <MainTabs />
        </TabPane>
        <TabPane tabId="2">
          <SecondTabs />
        </TabPane>
      </TabContent>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  users: state.users
})

export default connect(mapStateToProps)(Profiles) 