import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { connect } from 'react-redux'

import NavBar from "./Components/Navigations/NavBar";
import Footer from "./Components/Navigations/Footer";
import Home from "./Components/Home/Home"
import SignIn from './Components/Users/SignIn'
import SignUp from './Components/Users/SignUp'
import Profiles from './Components/Profiles/Profiles'
import ContactUs from "./Components/ContactUs/ContactUs";
import ProductGroup from "./Components/Products/ProductGroup";
import ProductDetail from "./Components/Products/ProductDetail";
import ProductItem from "./Components/Products/ProductItem"
import ProductItemDetail from './Components/Products/ProductItemDetail'
import ProductDetailAll from "./Components/Products/ProductDetailAll";
import ProductSearch from "./Components/Products/ProductSearch"
import Tracking from "./Components/Tracking/Tracking"
// import AddTracking from "./Components/Tracking/AddTracking"
import ForgotPassword from "./Components/Users/ForgotPassword";
import ResetPassword from "./Components/Users/ResetPassword";
import VerifyUser from "./Components/Users/VerifyUser";
import ShoppingCarts from "./Components/ShoppingCarts/ShoppingCarts";
import SearchStock from "./Components/SearchStock/SearchStock";

//Marketing
import SalesPage from './Components/Marketing/SalesPage'

//Redirect
import Redirect from "./Components/Redirect/Redirect"
import ScanStock from "./Components/SearchStock/ScanStock"


const App = () => {

  return (
    <div>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/product" element={<ProductGroup />} />
          <Route path="/productdetail/:id" element={<ProductDetail />} />
          <Route path="/productitem/:article" element={<ProductItem />} />
          <Route path='/productitemdetail/:id' element={<ProductItemDetail />} />
          <Route path="/products" element={<ProductDetailAll />} />
          <Route path="/productsearch" element={<ProductSearch />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/tracking" element={<Tracking />} />
          {/* <Route path="/addtracking" element={AddTracking} /> */}
          <Route path='/signin' element={<SignIn />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/resetpassword' element={<ResetPassword />} />
          <Route path='/verifyuser' element={<VerifyUser />} />
          <Route path='/profile' element={<Profiles />} />
          <Route path='/shoppingcarts' element={<ShoppingCarts />} />
          {/* <Route path='/searchstock' element={SearchStock} /> */}
          <Route path='/searchstock/:code' element={<SearchStock />} />
          <Route path='/salespage' element={<SalesPage />} />
          <Route path='/redirect' element={(props) => <Redirect {...props} />} />
          <Route path='/scanstock' element={<ScanStock />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  )
}

const mapStateToProps = (state) => ({
  users: state.users
})

export default connect(mapStateToProps)(App)