import React from 'react'
import { Card, CardBody, Navbar, NavItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Document } from '../../Documents'

const WebFooter = () => {
    return (
        <Card style={{ bottom: 0, left: 0, right: 0, }} >
            <CardBody>
                <Navbar style={{ height: 10, flexDirection: 'row', alignContent: 'center', listStyle: 'none' }} >
                    <NavItem>
                        <Link to="/">Design by K.P.M Textile Co.,Ltd </Link>
                    </NavItem>
                    <NavItem>Copyright@2018 <span>V.{Document.VersionCode}</span></NavItem>
                </Navbar>
            </CardBody>
        </Card>
    )
}

export default WebFooter