import React, { useEffect } from 'react'
import { Container, Col, Row, Card, CardFooter, CardImg, Spinner, BreadcrumbItem, Breadcrumb } from 'reactstrap'
import axios from '../../Services/Axios'
import ImageUrl from '../../Services/ImageUrl'
import { Link } from 'react-router-dom'

import SearchBar from '../Home/SearchBar'

import { connect } from 'react-redux'
import { setProductDetailAllData, setProductDetailAllLoading } from '../../Redux/Actions/ProductActions/ProductDetailAllActions'

const ProductDetailAll = ({ productDetailAllData, loading, setProductDetailAllData, setProductDetailAllLoading }) => {

    useEffect(() => {
        getProductDetailAllData()
    }, [])

    const getProductDetailAllData = async () => {
        setProductDetailAllLoading(true)
        axios({
            method: 'GET',
            url: '/pdd',
            params: {
                keyword: '%'
            }
        }).then((res) => {
            setProductDetailAllData(res.data.data)
            setProductDetailAllLoading(false)
        }).catch((err) => {
            console.log(err)
            setProductDetailAllLoading(false)
        })
    }

    return (
        <Container>
            <div style={{ marginTop: 5 }}>
                <SearchBar />
            </div>
            <Row>
                <Breadcrumb >
                    <BreadcrumbItem>
                        <Link to='/'>Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to='/product'>Categories</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>All Products</BreadcrumbItem>
                </Breadcrumb>
            </Row>
            <Row style={{ justifyContent: 'center' }} >
                {!loading ? productDetailAllData.map((items, index) => (
                    <Col key={index} sm={5} md={4} >
                        <Link to={`/productitem/` + items.pdd_code}>
                            <Card style={{ marginBottom: 20, marginTop: 20 }} >
                                <CardImg src={ImageUrl.baseUrl + '/pdd/' + items.pdd_img} />
                                <CardFooter>{items.pdd_name}</CardFooter>
                            </Card>
                        </Link>
                    </Col>
                )) : (
                    <Row style={{ marginBottom: 100, marginTop: 100 }}>
                        <Spinner type="grow" color="primary" />
                    </Row>
                )}
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    productDetailAllData: state.productDetailAll.productDetailAllData,
    loading: state.productDetailAll.loading
})

const mapDispatchToProps = (dispatch) => ({
    setProductDetailAllData: (data) => {
        dispatch(setProductDetailAllData(data))
    },
    setProductDetailAllLoading: (loading) => {
        dispatch(setProductDetailAllLoading(loading))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailAll)