import React from 'react'
import { Container} from 'reactstrap'
import { connect } from 'react-redux'
const MainTab = (props) => {

    return (
        <Container>

        </Container>
    )
}

const mapStateToProps = (state) => ({
    users: state.users,
    shoppingCarts: state.shoppingCarts
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(MainTab)