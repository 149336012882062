import React from 'react'
import { Row, Col, CardImg } from 'reactstrap'
import { FaHandPointDown, FaHandPointLeft, FaHandPointRight } from 'react-icons/fa'


const OurProducts = () => {
    return (
        <div >
            <h4>ผ้าของเราทำอะไรได้บ้าง</h4>
            <Row style={{ margin: 5, marginTop: 20, textAlign: 'center' }}>
                <Col xl={2} md={4} sm={4}><p>แบบนี้  <FaHandPointDown style={{ fontSize: 28, color: 'red' }} /></p><CardImg src='https://kpmtextile.com/service/uploads/image/pdg/img_5a17f9de735d3.jpg' /></Col>
                <Col xl={2} md={4} sm={4}><p>แบบนี้  <FaHandPointDown style={{ fontSize: 28, color: 'red' }} /></p><CardImg src='https://kpmtextile.com/service/uploads/image/pdg/img_5a1800c3c58b2.jpg' /></Col>
                <Col xl={2} md={4} sm={4}><p>แบบนี้  <FaHandPointDown style={{ fontSize: 28, color: 'red' }} /></p><CardImg src='https://kpmtextile.com/service/uploads/image/pdg/img_5a18024e6ad3f.jpg' /></Col>
                <Col xl={2} md={4} sm={4}><p>แบบนี้  <FaHandPointDown style={{ fontSize: 28, color: 'red' }} /></p><CardImg src='https://kpmtextile.com/service/uploads/image/pdg/img_5a183f8b6aec1.jpeg' /></Col>
                <Col xl={2} md={4} sm={4}><p>แบบนี้  <FaHandPointDown style={{ fontSize: 28, color: 'red' }} /></p><CardImg src='https://kpmtextile.com/service/uploads/image/pdg/img_5a18418b74ca2.jpg' /></Col>
                <Col xl={2} md={4} sm={4}><p>แล้วก็แบบนี้  <FaHandPointDown style={{ fontSize: 28, color: 'red' }} /></p><CardImg src='https://kpmtextile.com/service/uploads/image/pdg/pdg_1532071761327.jpg' /></Col>
            </Row>
            <Row style={{ margin: 10, marginTop: 20 }} >
                <h5 style={{ marginRight: 20 }} >และมีอีกมากมาย</h5>
                <a href='https://kpmtextile.com/product' ><FaHandPointRight style={{ fontSize: 24 }}/> Click Me <FaHandPointLeft style={{ fontSize: 24 }} /></a>
            </Row>
            <div style={{ margin: 10, marginTop: 20 }}>
                <h5>ผ้าของเราแบ่งเป็น 4 กลุ่มหลักๆ</h5>
                <p style={{ color: 'blue' }} >กลุ่มที่ 1 ผ้า คอตต้อน 100% (Cotton 100%) และ คอตต้อนสเปนเด็กซ์ (Cotton Spandex)</p>
                <p>ผ้าคอตต้อนสเปนเด็กซ์ (Cotton Spandex) มีส่วนผสมของยางยืดในเส้นด้ายพุ่ง ทำให้ผ้าสามารถยืดได้ สวมใส่สบาย ไม่อึดอัด</p>
                <p>หมาะสำหรับตัดเป็นกางเกงขาสั้น และกางเกงขายาว กางเกงทรงกระบอก ทรงยีนส์ (Jeans Style) ทรงชิโน่ (Chino) ทรงขาเดฟ (Slim fit) และอื่นๆ</p>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <CardImg style={{ width: '60%' }} src='https://kpmtextile.com/service/uploads/image/home/slide1.jpg' />
                </div>
                <p style={{ color: 'blue' }}>กลุ่มที่ 2 ผ้า ทีซี (TC) ผ้า ทีอาร์ (TR) และ โพลีเอสเตอร์ (Polyester) </p>
                <p>เหมาะสำหรับตัดชุดยูนิฟอร์ม ชุดช็อป ชุดหมี เสื้อแจ๊กเก๊ต ชุดนักเรียน ชุดนักศึกษา เสื้อกีฬาสีโรงเรียน ชุดพยาบาล ชุดตำรวจ ชุดราชกาล ชุดทหาร หมวก ผ้ากันเปื้อน และอื่นๆ โดยมีคุณสมบัติไม่ยับง่าย รีดง่าย ดูแลง่าย สีติดทนนานไม่ซีดเร็ว</p>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <CardImg style={{ width: '60%' }} src='https://kpmtextile.com/service/uploads/image/home/slide2.jpg' />
                </div>
                <p style={{ color: 'blue' }}>กลุ่มที่ 3 ผ้าแฟชั่น (Fashion Fabrics) และผ้าพิมพ์ลาย (Printing Fabrics)</p>
                <p>เหมาะสำหรับ ตัดชุดผู้หญิงแฟชั่น ตามใจลูกค้าชอบ ออกแบบได้หลากหลาย</p>
                <p style={{ color: 'blue' }}>กลุ่มที่ 4 ผ้ายีนส์ (Denim Fabrics)</p>
                <p>เหมาสำหรับตัดกางเกงยีนส์ หญิง-ชาย และ เสื้อยีนส์ ชุดเดรสยีนส์ ทั้งแบบยืดได้ และ ไม่ยืด มีตั้งแต่ ยีนส์ 4.5 ออน ถึง ยีนส์ 14.25 ออน</p>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <CardImg style={{ width: '60%' }} src='https://kpmtextile.com/service/uploads/image/salespage/jeans.jpg' />
                </div>
            </div>
        </div>
    )
}

export default OurProducts
