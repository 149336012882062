import { SET_PRODUCT_ITEM_DATA, SET_PRODUCT_ITEM_LOADING, SET_PRODUCT_ITEM_STOCK, SET_PRODUCT_ITEM_ARTICLE, SET_PRODUCT_ITEM_IMAGE, SET_PRODUCT_ITEM_NAME } from '../../Type'

export const setProductItemData = (data) => ({
    type: SET_PRODUCT_ITEM_DATA,
    setData: data
})
export const setProductItemLoading = (loading) => ({
    type: SET_PRODUCT_ITEM_LOADING,
    setLoading: loading
})
export const setProdcutItemStock = (stock) => ({
    type: SET_PRODUCT_ITEM_STOCK,
    setStock: stock
})
export const setProductItemArticle = (article) => ({
    type: SET_PRODUCT_ITEM_ARTICLE,
    setArticle: article
})
export const setProductItemImage = (image)=>({
    type:SET_PRODUCT_ITEM_IMAGE,
    setImage:image
})
export const setProductItemName = (name) =>({
    type:SET_PRODUCT_ITEM_NAME,
    setName:name
})