import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  CardImg
} from 'reactstrap'
import ImageUrl from '../../Services/ImageUrl'

const items = [
  {
    src: ImageUrl.baseUrl + '/home/slide1.jpg',
    altText: 'Slide 1',
    caption: 'ผ้า 100% Contton '
  },
  {
    src: ImageUrl.baseUrl + '/home/slide2.jpg',
    altText: 'Slide 2',
    caption: 'ผ้า ลายทหารเครื่องแบบ'
  },
  {
    src: ImageUrl.baseUrl + '/home/slide3.jpg',
    altText: 'Slide 3',
    caption: 'ผ้า Cotton Spandex'
  }
]

const HomeCarousel = () => {
  const [state, setState] = useState({ activeIndex: 0 })

  let animating
  function onExiting() {
    animating = true
  }

  function onExited() {
    animating = false
  }

  function next() {
    if (animating) return;
    const nextIndex = state.activeIndex === items.length - 1 ? 0 : state.activeIndex + 1;
    setState({ activeIndex: nextIndex })
  }

  function previous() {
    if (animating) return;
    const nextIndex = state.activeIndex === 0 ? items.length - 1 : state.activeIndex - 1;
    setState({ activeIndex: nextIndex });
  }

  function goToIndex(newIndex) {
    if (animating) return;
    setState({ activeIndex: newIndex });
  }

  const { activeIndex } = state;

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={onExiting}
        onExited={onExited}
        key={item.src}
        style={{ borderRadius: 5 }}
      >
        <CardImg src={item.src} alt={item.altText} style={{ maxWidth: 1271, height: undefined, borderRadius: 5, resize: 'horizontal' }} />
        <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      style={{ transform: 'auto', width: 'auto', height: 'auto' }}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex}

      />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  )
}

export default HomeCarousel