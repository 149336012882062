import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setProductItemDetailBookingQty, setProductItemDetailData, setProductItemDetailLoading, setProductItemDetailBookingQtyPlus, setProductItemDetailBookingQtyMiner } from '../../Redux/Actions/ProductActions/ProductItemDetailAction'
import { setShoppingCartUnConfirmData, setShoppingCartUnconfirmCount, setShoppingCartUnconfirmTotal } from '../../Redux/Actions/ShoppingCartsActions/ShoppingCartsActions'
import Axios from '../../Services/Axios'
import ImageUrl from '../../Services/ImageUrl'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Container, ListGroup, ListGroupItem, Button, Spinner, Card, CardImg, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { FaCartPlus, FaShoppingCart } from 'react-icons/fa'


const ProductItemDetail = ({ setProductItemDetailBookingQty, productItemDetail, users, productItemDetailLoading, setProductItemDetailData, setProductItemDetailLoading, setProductItemDetailBookingQtyPlus, setProductItemDetailBookingQtyMiner, setShoppingCartUnConfirmData, setShoppingCartUnconfirmCount, setShoppingCartUnconfirmTotal }) => {

    const [state, setState] = useState({
        P_COM: 'kp',
        P_USER: 'kpm',
        P_PRODUCT_CODE: null,
        P_PAGE: 1,
        stockQty: null,
        message: null,
        article: null,
        code: null,
        buttonIsDisble: false,
        imageStatus: null
    })

    useEffect(() => {
        getProductItemDetailData()
    }, [])

    const { id } = useParams()
    const navigate = useNavigate()
    const getProductItemDetailData = async () => {
        setProductItemDetailLoading(true)
        await Axios({
            method: 'POST',
            url: '/pdidetail/' + id
        }).then((res) => {
            setProductItemDetailData(res.data.data[0])
            setProductItemDetailLoading(false)
            res.data.data[0].map((rows) => {
                console.log(rows)
                setState({
                    article: rows.pdi_article,
                    code: rows.pdi_code
                })
                onCheckStock(rows.pdi_article)
            })
        }).catch((err) => {
            console.log(err)
        })
    }


    const getShoppingCartsUnConfirmData = async () => {
        setTimeout(() => {
            if (users.userToken === null) { console.log('Did not login') } else {
                Axios({
                    method: 'POST',
                    url: '/getcart',
                    data: {
                        status: 'UNCONFIRM',
                        user_id: users.userId
                    },
                    headers: {
                        Authorization: 'Bearer ' + users.userToken
                    }
                }).then((res) => {
                    setShoppingCartUnConfirmData(res.data.data)
                    // console.log(res)
                    const cartItem = res.data.data
                    var totalCarts = 0
                    for (var i = 0; i < cartItem.length; i++) {
                        totalCarts += Number(cartItem[i].qty)
                    }
                    setShoppingCartUnconfirmTotal(totalCarts)
                    setShoppingCartUnconfirmCount(cartItem.length)
                }).catch((err) => {
                    console.log(err)
                })
            }
        }, 300)
    }

    const onCheckStock = async (article) =>
        await Axios({
            url: `/getstock`,
            method: 'POST',
            data: {
                P_COM: state.P_COM,
                P_USER: state.P_USER,
                P_PRODUCT_CODE: article,
                P_PAGE: state.P_PAGE
            }
        }).then((res) => {
            res.data.result.forEach((value, i) => {
                // console.log(value, i)
                setState({
                    stockQty: value.TOTAL_QTY
                })
            })
        }).catch((err) => {
            console.log(err)
        })

    const numberFormat = (number) => {
        return new Intl.NumberFormat('en-IN').format(number)
    }

    const onAddCart = async () => {
        if (productItemDetail.bookingQty <= 0) {
            alert('กรุณาใส่จำนวนที่ต้องการ')
        } else {
            await Axios({
                method: 'POST',
                url: '/addcart',
                data: {
                    id: null,
                    user_id: users.userId,
                    mobile: users.userMobile,
                    pdi_article: state.article,
                    qty: productItemDetail.bookingQty,
                    status: 'UNCONFIRM',
                    cart_total_id: null
                },
                headers: {
                    Authorization: 'Bearer ' + users.userToken
                }
            }).then((res) => {
                getShoppingCartsUnConfirmData()
                setState({
                    message: 'ท่านได้เพิ่ม ' + state.article + ' เข้าไปในรถเข็นสินค้าแล้ว จำนวน ' + productItemDetail.bookingQty + ' Yds .',
                    buttonIsDisble: true
                })
                setProductItemDetailBookingQty(0)
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    return (
        <Container style={{ marginBottom: 20 }}>
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to='/'>Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Link to='/products'>All Products</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Link to={'/productitem/' + state.code}>{state.code}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>{state.article}</BreadcrumbItem>
            </Breadcrumb>
            <div style={{ backgroundColor: '#f2f2f2' }} >
                {!productItemDetailLoading ? productItemDetail.productItemDetailData.map((rows, index) => (
                    <Row key={index} style={{ flexDirection: 'row' }} >
                        <Col xs={12} md={12} xl={8} >
                            <Card>
                                <CardImg onError={() => { setState({ imageStatus: 'Load Error' }) }}
                                    onLoad={() => { setState({ imageStatus: 'Loaded' }) }}
                                    src={ImageUrl.baseUrl + '/pdi/' + rows.pdi_code + '/' + rows.pdi_image} alt={rows.pdi_name} />
                            </Card>
                        </Col>
                        <Col xs={12} md={12} xl={4}  >
                            <ListGroup style={{ width: '100%' }} >
                                {/* <ListGroupItem>ID: {rows.pdi_id}</ListGroupItem> */}
                                <ListGroupItem>รหัสผ้า : {rows.pdi_code}</ListGroupItem>
                                <ListGroupItem>รหัสสีผ้า : {rows.pdi_article}</ListGroupItem>
                                <ListGroupItem>ชื่อผ้า : {rows.pdi_name}</ListGroupItem>
                                <ListGroupItem>จำนวนหลาในคลัง : {numberFormat(state.stockQty)} Yards</ListGroupItem>
                                <ListGroupItem>รายละเอียดผ้า : {rows.pdi_detail}</ListGroupItem>
                                <div>
                                    {users.userIsLogin ? (
                                        <div>
                                            <ListGroupItem> จำนวนสั่งซื้อ :  <input type='float'
                                                onChange={(e) => { setProductItemDetailBookingQty(e.target.value) }}
                                                defaultValue={productItemDetail.bookingQty}
                                                style={{ maxWidth: 100, margin: 5 }} />
                                                <Button outline disabled={state.buttonIsDisble} onClick={() => { onAddCart() }} >
                                                    <FaCartPlus style={{ fontSize: 20, marginRight: 10 }} />
                                                    Add To Carts</Button>
                                            </ListGroupItem>
                                            <ListGroupItem >
                                                <Link to='/shoppingcarts'><FaShoppingCart /> Go to shopping cart </Link>
                                            </ListGroupItem>
                                        </div>
                                    ) : (
                                        <ListGroupItem>
                                            <Button outline onClick={() => {
                                                navigate('/signin')
                                            }} >Login</Button>
                                            <Button outline onClick={() => {
                                                navigate('/signup')
                                            }} >Register</Button>
                                        </ListGroupItem>
                                    )}
                                    <ListGroupItem style={{ color: 'red' }} >{state.message}</ListGroupItem>
                                </div>
                            </ListGroup>
                        </Col>
                    </Row>
                )) : (
                    <div>
                        <Spinner type='grow' />
                    </div>
                )}
            </div>
        </Container>
    )
}
const mapStateToProps = (state) => ({
    productItemDetail: state.productItemDetail,
    users: state.users
})

const mapDispatchToProps = (dispatch) => ({
    setProductItemDetailData: (data) => {
        dispatch(setProductItemDetailData(data))
    },
    setProductItemDetailLoading: (data) => {
        dispatch(setProductItemDetailLoading(data))
    },
    setProductItemDetailBookingQtyPlus: (data) => {
        dispatch(setProductItemDetailBookingQtyPlus(data))
    },
    setProductItemDetailBookingQtyMiner: (data) => {
        dispatch(setProductItemDetailBookingQtyMiner(data))
    },
    setProductItemDetailBookingQty: (data) => {
        dispatch(setProductItemDetailBookingQty(data))
    },
    setShoppingCartUnConfirmData: (data) => {
        dispatch(setShoppingCartUnConfirmData(data))
    },
    setShoppingCartUnconfirmCount: (data) => {
        dispatch(setShoppingCartUnconfirmCount(data))
    },
    setShoppingCartUnconfirmTotal: (data) => {
        dispatch(setShoppingCartUnconfirmTotal(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductItemDetail)