import React from 'react'
import { Container } from "reactstrap"
import ProductGroup from "../Products/ProductGroup"
import Carousel from "./Carousels"
import Jumboron from "./Jumboron"
import Helmet from "react-helmet"
import SearchBar from "../Home/SearchBar"
import ContactForm from './ContactForm'
const Home = (props) => {
 
  return (
    <Container>
      <Helmet>
        <title>K.P.M Textile Co.,Ltd ผ้าตราม้าคู่</title>
      </Helmet>
      <div style={{ marginTop: 5 }}>
        <SearchBar history={props.history} />
      </div>
      <div style={{ marginTop: 5 }}>
        <Carousel />
      </div>
      <Jumboron />
      <div style={{ backgroundColor: '#f2f2f2' }} >
        <ProductGroup />
      </div>
      <div style={{ marginBottom: 20 }} >
        <ContactForm />
      </div>
    </Container>
  )
}
export default Home